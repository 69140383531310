import React from "react";
import Button from "../../Button/Button";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";
import moment from "moment";

const MembershipBox = ({ membershipInfo, symbol, onCtaClick }) => {
  const {
    id,
    currentPeriodStart,
    currentPeriodEnd,
    cancelAtPeriodEnd,
    usedEvents,
    events,
    amount,
    currency,
  } = membershipInfo;

  const bars = [];

  const balance = events;
  const sessionsLeft = events - usedEvents;

  const renewalDate = moment(currentPeriodEnd).format("MM.DD.YYYY");

  const handleCtaClick = React.useCallback(() => {
    onCtaClick && onCtaClick();
  }, [onCtaClick]);

  for (let i = 0; i < balance; i++) {
    bars.push(
      <div
        key={i}
        className={`tbk-h-1 tbk-w-full ${i == 0 ? "tbk-rounded-l-full" : ""} ${
          i == balance - 1 ? "tbk-rounded-r-full" : ""
        } tbk-overflow-hidden ${
          i < sessionsLeft ? "tbk-bg-coral" : "tbk-bg-blue-grey-light"
        }`}
      />
    );
  }

  return (
    <div className="tbk-relative tbk-rounded-lg tbk-bg-basic-white tbk-p-4 tbk-text-center tbk-shadow-trube">
      <h2 className="tbk-text-title-bold lg:tbk-text-h2-black tbk-text-primary lg:!tbk-leading-tight">
        Your Membership
      </h2>
      <p className="tbk-text-main tbk-mb-3 tbk-text-blue-grey">
        {cancelAtPeriodEnd ? "Expires" : "Renewal"}: {renewalDate}
      </p>
      <p className="tbk-text-h3-subtitle tbk-mb-1 tbk-text-blue-grey">
        {sessionsLeft} out of {balance} classes left
      </p>
      <div className="tbk-mb-2 tbk-flex tbk-gap-0.5 lg:tbk-mb-4">{bars}</div>

      {sessionsLeft === 0 ? (
        <p className="tbk-text-main tbk-mb-4 tbk-text-primary">
          Purchase additional classes for {symbol}20 per class.
        </p>
      ) : null}
      <Button
        size="md"
        variant="secondary"
        className="tbk-mx-auto tbk-w-40"
        endIcon={<ArrowRightIcon />}
        // href="#"
        onClick={handleCtaClick}
      >
        Manage
      </Button>
    </div>
  );
};

export default MembershipBox;
