/**
 * Slider Next Button component for navigating to the next slide.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/08/06
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React from "react";

// Icons
import ArrowRightIcon from "../../icons/ArrowRightIcon";

export const SliderNextButton = ({
  className,
  buttonClassName,
  iconClassName,
  style,
  onClick,
  onBtnClick,
}) => {
  const handleClick = () => {
    onBtnClick && onBtnClick();
    onClick && onClick();
  };
  return (
    <button
      className={`${className ? `${className} ` : ""}${
        buttonClassName ? `${buttonClassName} ` : ""
      }`}
      style={style}
      onClick={handleClick}
    >
      <ArrowRightIcon
        className={`${iconClassName ? `${iconClassName} ` : ""}tbk-inline`}
      />
    </button>
  );
};

export default SliderNextButton;
