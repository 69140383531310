import React from "react";

export default function Calendar24Icon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-shrink-0`}
    >
      <path
        d="M46.9333 28.2666V24.5333C46.9333 24.0382 46.7366 23.5634 46.3865 23.2134C46.0365 22.8633 45.5617 22.6666 45.0666 22.6666H43.1999V24.5333C43.1999 25.0284 43.0033 25.5031 42.6532 25.8532C42.3031 26.2033 41.8283 26.4 41.3333 26.4C40.8382 26.4 40.3634 26.2033 40.0133 25.8532C39.6633 25.5031 39.4666 25.0284 39.4666 24.5333V22.6666H24.5333V24.5333C24.5333 25.0284 24.3366 25.5031 23.9865 25.8532C23.6365 26.2033 23.1617 26.4 22.6666 26.4C22.1715 26.4 21.6967 26.2033 21.3467 25.8532C20.9966 25.5031 20.7999 25.0284 20.7999 24.5333V22.6666H18.9333C18.4382 22.6666 17.9634 22.8633 17.6133 23.2134C17.2633 23.5634 17.0666 24.0382 17.0666 24.5333V28.2666H46.9333ZM46.9333 32H17.0666V43.1999C17.0666 43.695 17.2633 44.1698 17.6133 44.5199C17.9634 44.87 18.4382 45.0666 18.9333 45.0666H45.0666C45.5617 45.0666 46.0365 44.87 46.3865 44.5199C46.7366 44.1698 46.9333 43.695 46.9333 43.1999V32ZM43.1999 18.9333H45.0666C46.5518 18.9333 47.9762 19.5233 49.0264 20.5735C50.0766 21.6237 50.6666 23.0481 50.6666 24.5333V43.1999C50.6666 44.6852 50.0766 46.1095 49.0264 47.1597C47.9762 48.21 46.5518 48.7999 45.0666 48.7999H18.9333C17.448 48.7999 16.0237 48.21 14.9735 47.1597C13.9233 46.1095 13.3333 44.6852 13.3333 43.1999V24.5333C13.3333 23.0481 13.9233 21.6237 14.9735 20.5735C16.0237 19.5233 17.448 18.9333 18.9333 18.9333H20.7999V17.0666C20.7999 16.5715 20.9966 16.0968 21.3467 15.7467C21.6967 15.3966 22.1715 15.2 22.6666 15.2C23.1617 15.2 23.6365 15.3966 23.9865 15.7467C24.3366 16.0968 24.5333 16.5715 24.5333 17.0666V18.9333H39.4666V17.0666C39.4666 16.5715 39.6633 16.0968 40.0133 15.7467C40.3634 15.3966 40.8382 15.2 41.3333 15.2C41.8283 15.2 42.3031 15.3966 42.6532 15.7467C43.0033 16.0968 43.1999 16.5715 43.1999 17.0666V18.9333Z"
        fill="currentColor"
      />
    </svg>
  );
}
