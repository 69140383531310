/**
 * Gender picker for questionnaire
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/11/26
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useEffect, useState, useCallback } from "react";
// import { Component } from "react";
import { useAppState } from "../../../state";

// Components
import Button from "../../Button/Button";

// Icons
import ArrowRight from "../../../icons/ArrowRightIcon";
import Calendar from "../../../icons/Calendar24Icon";
import CaretDownIcon from "../../../icons/CaretDownIcon";

const SelectOption = ({
  id,
  className,
  disabled,
  checked,
  text,
  value,
  onChange,
}) => {
  const handleChange = useCallback(() => {
    onChange && onChange(value);
  }, [value, onChange]);

  return (
    <div className={`${className} tbk-relative tbk-flex tbk-p-1`}>
      <input
        type="radio"
        id={`option_${id}`}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <label
        className="tbk-text-h3-subtitle tbk-w-7 tbk-grow tbk-pl-5 tbk-text-grey-main"
        htmlFor={`option_${id}`}
      >
        {text}
      </label>
    </div>
  );
};

const SetGender = ({ questions, onNext, onSkip }) => {
  const {
    //   gender,
    //   setGender: setUserGender,
    isFetching,
  } = useAppState();
  // const [questions, setQuestions] = useState([]);
  const [selectedGenderOption, setSelectedGenderOption] = useState(null);
  const [canSend, setCanSend] = useState(false);

  const handleSubmit = useCallback(
    (evt) => {
      evt && evt.preventDefault(); // We might call this callback directly, it's not a good practice thou

      if (selectedGenderOption) {
        //setUserGender(selectedGenderOption);

        onNext({ gender: selectedGenderOption });
      }
    },
    [selectedGenderOption]
  );

  const handleSkip = useCallback(
    (evt) => {
      onSkip && onSkip({ gender: selectedGenderOption });
    },
    [onSkip, selectedGenderOption]
  );

  const handleGenderChange = useCallback(
    (val) => {
      if (val) {
        setCanSend(true);
      }
      setSelectedGenderOption(val);
    },
    [
      /*user*/
    ]
  );

  return (
    <>
      <form className="tbk-mb-4" onSubmit={handleSubmit}>
        <div className="tbk-text-title-bold tbk-mb-1">Gender</div>
        <div className="tbk-text-main tbk-mb-4 tbk-text-blue-grey">
          Please select your gender:
        </div>

        <div className="tbk-mb-7">
          {questions && questions.id
            ? questions.answers.map((val) => (
                <SelectOption
                  key={val.id}
                  id={val.id}
                  className="tbk-mb-1"
                  text={val.answer}
                  value={val.id}
                  checked={selectedGenderOption === val.id}
                  onChange={handleGenderChange}
                />
              ))
            : null}
        </div>

        <Button
          type="submit"
          className="tbk-mb-3 tbk-w-full"
          //variant="secondary"
          disabled={!canSend || isFetching}
          endIcon={<ArrowRight />}
          showLoader={isFetching}
        >
          Next
        </Button>
      </form>
      <div className="tbk-text-center">
        <button
          className="tbk-text-main tbk-inline-block tbk-text-blue-grey tbk-underline hover:tbk-text-blue-grey-light"
          disabled={isFetching}
          onClick={handleSkip}
        >
          Skip Questionnaire
        </button>
      </div>
    </>
  );
};

export default SetGender;
