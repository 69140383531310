/**
 * Choose Your Region Modal component.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/09/12
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import * as Sentry from "@sentry/browser";
// import Cookies from "js-cookie";
import { useAppState } from "../../state";
import { useRegion, getRegion } from "../../context/RegionProvider";

// Components
import Button from "../Button/Button";

// Hooks
import useTheme from "../ThemeProvider/useTheme/useTheme";

// Utils
import { clientWidth } from "../../utils";
import { modalStyles } from "../../constants";

// Icons
import ArrowRightIcon from "../../icons/ArrowRightIcon";

// Images
import Image from "./ChooseYourCityImage";

const Checkbox = ({ children, className, id, name, value, onChange }) => {
  const handleChange = React.useCallback(
    (e) => {
      onChange && onChange(!value);
    },
    [value]
  );

  return (
    <div className={className}>
      <input
        id={id}
        type="checkbox"
        name={name}
        disabled={false}
        className="tb-checkbox-new_"
        checked={value}
        onChange={handleChange}
      />
      <label
        htmlFor={id}
        tabIndex="0"
        className="tbk-text-small tbk-mb-0 tbk-flex tbk-h-3 tbk-items-center tbk-pl-4 tbk-text-primary"
      >
        {children}
      </label>
    </div>
  );
};

const SelectOption = ({
  id,
  className,
  disabled,
  checked,
  text,
  value,
  onChange,
}) => {
  const handleChange = useCallback(() => {
    onChange && onChange(value);
  }, [value, onChange]);

  return (
    <div className={`${className} tbk-relative tbk-flex tbk-p-1`}>
      <input
        type="radio"
        id={`option_${id}`}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <label
        className="tbk-text-main tbk-w-7 tbk-grow tbk-pl-5 tbk-text-primary"
        htmlFor={`option_${id}`}
      >
        {text}
      </label>
    </div>
  );
};

// Not used
function getStoredRegion() {
  return localStorage.getItem("userRegion");
}

const selectedRegion = getRegion();

export const CityPickerLS = ({ city, isOpen, onClose, onCityChange }) => {
  const { theme } = useTheme();
  const { setRegion } = useRegion();

  const { user, error, updateCustomerInfo } = useAppState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allLocationsCheckbox, setAllLocationsCheckbox] = useState(
    city === "ALL" || city == null
  );
  const [laCheckbox, setLaCheckbox] = useState(city === "LOS_ANGELES");
  const [sanDiegoCheckbox, setSanDiegoCheckbox] = useState(
    city === "SAN_DIEGO"
  );

  const modalResolveRef = useRef();

  const openModal = () => {
    return new Promise((resolve) => {
      modalResolveRef.current = resolve;

      setIsModalOpen(true);
    });
  };

  const closeModal = () => {
    if (modalResolveRef.current) {
      modalResolveRef.current();
      modalResolveRef.current = null;

      onClose && onClose();
    }

    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      // const selectedRegion = user ? user.region : getRegion();

      // setUsaCheckbox(selectedRegion !== "GB");
      // setUkCheckbox(selectedRegion === "GB");

      openModal();
    } else if (!isOpen) {
      closeModal();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.city === "LOS_ANGELES") {
      setAllLocationsCheckbox(false);
      setSanDiegoCheckbox(false);
      setLaCheckbox(true);
    } else if (user.city === "SAN_DIEGO") {
      setAllLocationsCheckbox(false);
      setSanDiegoCheckbox(true);
      setLaCheckbox(false);
    } else {
      setAllLocationsCheckbox(true);
      setSanDiegoCheckbox(false);
      setLaCheckbox(false);
    }

    // updateCustomerInfo({ city: cityChoise }, user.id);
  }, [user]);

  const handleAllLocationsCheckboxChange = () => {
    setAllLocationsCheckbox(true);
    setLaCheckbox(false);
    setSanDiegoCheckbox(false);
  };

  const handleLaCheckboxChange = () => {
    setAllLocationsCheckbox(false);
    setLaCheckbox(true);
    setSanDiegoCheckbox(false);
  };

  const handleSanDiegoCheckboxChange = () => {
    setAllLocationsCheckbox(false);
    setLaCheckbox(false);
    setSanDiegoCheckbox(true);
  };

  const handleContinueClick = useCallback(() => {
    let cityChoise = laCheckbox
      ? "LOS_ANGELES"
      : sanDiegoCheckbox
      ? "SAN_DIEGO"
      : "ALL";

    // localStorage.setItem("userRegion", selectedRegion);

    if (city !== cityChoise) {
      if (user) {
        updateCustomerInfo({ city: cityChoise }, user.id);
      }
      onCityChange(cityChoise);
    }

    closeModal();
  }, [user, laCheckbox, sanDiegoCheckbox, city]);

  const defaultCustomStyle =
    clientWidth() < theme.responsive.breakpoints.sm
      ? Object.assign(
          {},
          {
            ...modalStyles.newMobileDefault,
          },
          {
            overlay: {
              ...modalStyles.newMobileDefault.overlay,
              zIndex: 1060,
            },
          }
        )
      : Object.assign(
          {},
          {
            ...modalStyles.default,
          },
          {
            overlay: {
              ...modalStyles.default.overlay,
              zIndex: 1060,
            },
            content: {
              ...modalStyles.default.content,
              width: "698px",
              padding: "32px",
            },
          }
        );

  return (
    <Modal
      appElement={document.getElementById("#app")}
      isOpen={isModalOpen}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={this.closeModal}
      style={defaultCustomStyle}
      contentLabel="Choose Your City"
    >
      {/*<Link role='button' onClick={this.closeModal}><Icon name='close' className='tb-pd-0' /></Link>*/}
      <div className="choose-your-region-modal tbk-rounded-lg tbk-bg-basic-white">
        <div className="tbk-items-center tbk-justify-center md:tbk-flex">
          <div className="tbk-text-center">
            <Image className="tbk-inline-block" />
          </div>
          <div className="tbk-px-4 md:tbk-pl-4 md:tbk-pr-4">
            <h2 className="tbk-text-title-bold md:tbk-text-h2-black tbk-mb-1 tbk-text-primary lg:tbk-whitespace-nowrap lg:!tbk-leading-tight">
              Choose Your City
            </h2>
            <p className="tbk-text-main tbk-mb-2 tbk-text-blue-grey">
              In the US we're operating in Los Angeles & San Diego, but feel
              free to browse accross both locations.
            </p>

            <div className="tbk-mb-4">
              <SelectOption
                id={"ALL"}
                className="tbk-mb-0.5"
                text={"All Locations"}
                value={"ALL"}
                checked={allLocationsCheckbox}
                onChange={handleAllLocationsCheckboxChange}
              />

              <SelectOption
                id={"LOS_ANGELES"}
                className="tbk-mb-0.5"
                text={"Los Angeles"}
                value={"LOS_ANGELES"}
                checked={laCheckbox}
                onChange={handleLaCheckboxChange}
              />

              <SelectOption
                id={"SAN_DIEGO"}
                className="tbk-mb-0.5"
                text={"San Diego"}
                value={"SAN_DIEGO"}
                checked={sanDiegoCheckbox}
                onChange={handleSanDiegoCheckboxChange}
              />
            </div>

            {/*<SelectOption
              id={"ALL"}
              className="tbk-mb-0.5"
              text={"Los Angeles"}
              value={"LOS_ANGELES"}
              checked={allLocationsCheckbox}
              onChange={handleAllLocationsCheckboxChange}
            />*/}

            {/*<Checkbox
              id="allLocationsCheckbox"
              className="tbk-mb-0.5 tbk-p-1"
              value={allLocationsCheckbox}
              onChange={handleAllLocationsCheckboxChange}
            >
              <div className="tbk-text-main tbk-mb-0 tbk-text-primary">
                All Locations
              </div>
            </Checkbox>

            <Checkbox
              id="laCheckbox"
              className="tbk-mb-0.5 tbk-p-1"
              value={laCheckbox}
              onChange={handleLaCheckboxChange}
            >
              <div className="tbk-text-main tbk-mb-0 tbk-text-primary">
                Los Angeles
              </div>
            </Checkbox>

            <Checkbox
              id="sanDiegoCheckbox"
              className="tbk-mb-4 tbk-p-1"
              value={sanDiegoCheckbox}
              onChange={handleSanDiegoCheckboxChange}
            >
              <div className="tbk-text-main tbk-mb-0 tbk-text-primary">
                San Diego
              </div>
            </Checkbox>*/}

            <Button
              variant={"secondary"}
              className="tbk-w-full"
              endIcon={<ArrowRightIcon />}
              onClick={handleContinueClick}
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CityPickerLS.propTypes = {
  isOpen: PropTypes.string,
  onClose: PropTypes.func,
};

// const isRegionMatch = (regionCode) =>
//   regionCode === process.env.REACT_APP_REGION;

export default CityPickerLS;
