/**
 * Privacy Page
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/12/10
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useCallback, useState } from "react";
import { Title, Meta } from "react-meta-elements";

// Components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function PrivacyPage() {
  return (
    <>
      <Title>Privacy and Cookies Policy</Title>
      <Meta
        name="description"
        content={`On-demand, in person, personal training in London.`}
      />
      <Header showLinks={true} />

      <div className="tbk-bg-grey-light tbk-pb-5">
        <div className="tbk-mb-5 tbk-flex tbk-h-32 tbk-items-center tbk-justify-center tbk-bg-trust-safety-page-head-mobile tbk-bg-cover tbk-bg-no-repeat tbk-text-center md:tbk-h-44 md:tbk-bg-trust-safety-page-head">
          <h1 className="tbk-text-h2-black md:tbk-text-epic-bold tbk-text-basic-white">
            About this Privacy Policy
          </h1>
        </div>

        <div class="tbk-container tbk-mx-auto tbk-px-2 tbk-pb-7 lg:tbk-px-7">
          <div class="row">
            <div class="col col-12">
              <h1>About this Privacy Policy</h1>

              <p>
                Iwoo Limited of 5 Elstree Gate, Elstree Way, Borehamwood,
                Hertfordshire, United Kingdom, WD6 1JD with company number
                09042155, trading as TruBe ("<strong>TruBe</strong>" or "
                <strong>we</strong>" or "<strong>us</strong>”) cares about and
                is committed to protecting and respecting your privacy. TruBe is
                registered as a data controller for the purposes of the Data
                Protection Act 1998. Our registration number on the register of
                data controllers maintained by the Information Commissioner is{" "}
                <strong>ZA089757</strong>.
              </p>

              <p>
                This Privacy and Cookies Policy, together with the{" "}
                <a href="/terms">Terms</a>, applies to your use of the App, the
                Website (which together with the App we refer to in this policy
                as the “<strong>Sites</strong>”) and the TruBe Services we
                provide you via the Sites.
              </p>

              <p>
                Except where otherwise expressly stated or where it is clear
                from the context that this Privacy and Cookies Policy is only
                applicable to certain Users of the Sites and TruBe Services,
                this Privacy and Cookies Policy applies to all Users of the
                Sites and TruBe Services, including Service Providers.
              </p>

              <p>
                Capitalised words and phrases which are used in this Privacy and
                Cookies Policy have the meanings given to them in the Terms,
                unless otherwise defined in this Privacy and Cookies Policy.
              </p>

              <p>
                This Privacy and Cookies Policy sets out the basis on which any
                personal data we collect from you, or that you provide to us,
                will be processed by us. Please read the following carefully to
                understand our views and practices regarding your personal data
                and how we will treat it.
              </p>

              <p>
                Please note that this Privacy and Cookies Policy does not apply:
              </p>

              <ul>
                <li>
                  to third party websites that the Sites link to or from or any
                  third party services that may be accessible through them (for
                  example, websites which advertise the Sites or the TruBe
                  Services). TruBe does not accept any responsibility for such
                  websites’ privacy policies or for any personal data that may
                  be collected through such websites or services. Please check
                  the privacy policies of third party websites before you submit
                  any personal data to such websites; or
                </li>
                <li>
                  in respect of information that you choose to provide directly
                  to a Service Provider, such as information in relation to your
                  health and fitness that you provide during a Booking. The
                  Service Provider is a Controller of such information in their
                  own right and TruBe does not accept any responsibility for any
                  Service Provider’s use of such information.
                </li>
              </ul>

              <h2>
                <a name="about"></a>1. About us
              </h2>
              <p>
                At TruBe we really care about protecting and respecting your
                privacy and satisfying our obligations as a data controller.
              </p>
              <p>
                TruBe (“TruBe”, “we”, “us”) is the trading name of Iwoo Limited.
              </p>
              <p>
                Iwoo Limited (company number 09042155) is registered at the
                following address: 5 Elstree Gate, Elstree Way, Borehamwood,
                Hertfordshire, United Kingdom, WD6 1JD.
              </p>
              <p>
                TruBe is registered as a data controller for the purposes of the
                Data Protection Legislation. Our registration number on the
                register of data controllers maintained by the Information
                Commissioner is <strong>ZA089757</strong>.
              </p>
              <p>
                <a href="/contact.html">Contact us about this privacy policy</a>
              </p>

              <h2>
                <a name="2"></a>2. Information we collect about you
              </h2>
              <p>
                We collect information about you in a number of ways, in general
                when you use our Sites or TruBe Services. Any information we
                collect will only be used in accordance with this Privacy and
                Cookies Policy.
              </p>

              <h3 class="h5">
                2.1. Information you provide or make available to us
              </h3>
              <p>
                We collect personal information about you when you register for
                the App and use the Sites, including (as applicable) when you
                complete or update your profile on the App; communicate with us
                for any reason; make, accept or cancel Bookings; leave ratings,
                comments, feedback and/or scores for a Service Provider or User;
                and make Submissions. Such information may include your:
              </p>
              <ul>
                <li>name</li>
                <li>gender</li>
                <li>
                  contact details (including your address, email address and/or
                  telephone number)
                </li>
                <li>age, birthday and/or date of birth</li>
                <li>
                  Login (which may include the credentials you use for a third
                  party service such as Facebook if you choose to register for
                  the App using such credentials)
                </li>
                <li>
                  any information which is publicly available as part of your
                  Facebook profile, if you choose to register for the App using
                  your Facebook credentials
                </li>
                <li>
                  any information you include in your profile on the App and/or
                  provide to us in connection with a Booking, including any
                  sensitive personal data you choose to provide (for example,
                  relevant information about your health and fitness) and any
                  photograph you choose to upload from your computer, mobile
                  device, technology or other device (“<strong>Device</strong>
                  ”).
                </li>
              </ul>

              <h3 class="h5">
                2.2. Information about you provided to us by other Users
              </h3>
              <p>Information about Users:</p>
              <p>
                We may ask each Service Provider to provide us with feedback and
                an overall score in relation to a User’s condition, strength,
                endurance and performance during the Booking. Such information
                may include sensitive personal data (for example, about health
                and fitness) about the User.
              </p>
              <p>Information about Service Providers:</p>
              <p>
                If you are a Service Provider, then following each Booking we
                ask the relevant User to provide a rating and comments about you
                and the Service Provider Services you provided.
              </p>

              <h3 class="h5">2.3. Location Data</h3>
              <p>
                We may collect data about your location from your Device using
                GPS technology (“<strong>Location Data</strong>”). Location Data
                helps the App identify your location in order to help you make a
                Booking, or if you are a Service Provider to help us match you
                to Bookings based on your location. We will ask for your consent
                prior to collecting Location Data from you. You may withdraw
                such consent at any time by disabling the App’s access to your
                location using the settings within your Device or the App. If
                you do not give consent or withdraw it, you will still be able
                to use the App by manually identifying your location.
              </p>

              <h3 class="h5">2.4. Automatic Information Collection</h3>
              <p>
                2.4.1. We cookies on the Website to collect and provide us with
                information about how you access and use the Sites, as further
                described in section 4.4.
              </p>
              <p>
                2.4.2. We may also automatically collect your IP address or
                other unique identifier for the Device you use to access the
                Sites. A unique identifier is a number that is automatically
                assigned to your Device when you access a website, app or its
                servers, and we can identify your Device by this number ("
                <strong>Device Identifier</strong>"). Some mobile service
                providers may also provide us with the physical location of the
                Device used to access the Sites.
              </p>
              <p>
                We work closely with third parties (including, for example,
                business partners, sub-contractors in technical, payment and
                delivery services, analytics providers, search information
                providers and credit reference agencies) and may receive
                information about you from them.
              </p>

              <h3 class="h5">2.5. Payment Data</h3>
              <p>
                The App will ask you to input payment information (including
                credit card number, expiry date and CVV number) if you want to
                make a Booking. This information is collected directly by our
                third party payment processor, which uses such information to
                process payment from you. TruBe itself does not have access to
                this information.
              </p>
              <p>
                Users: If you have already provided your payment details through
                the App and attempt to make another Booking, we ask our third
                party payment processor for details of the payment method(s) you
                provided previously so that you do not have to enter them again.
                These details are only partially made available to us (for
                example, we only see the last four digits of your payment card
                number), meaning that we never have access to your complete
                payment details. The App will then display such details to you
                so that you may use them to make another Booking.
              </p>
              <p>
                Service Providers: If you are a Service Provider, we will ask
                you for your bank account details so that we can make payments
                to you in respect of Bookings you have attended. We will treat
                your bank account details in accordance with the terms of this
                Privacy and Cookies Policy.
              </p>

              <h2>
                <a name="3"></a>3. Our Use of Cookies and Related Technologies
              </h2>
              <p>
                3.1. The Sites use cookies and related technologies such as API
                keys and tokens to distinguish you from other users of the
                Sites. This helps us to provide you with a better experience
                when you use the Sites and to improve the Sites.
              </p>
              <p>
                3.2. Cookies and related technologies are small bits of text
                that are downloaded to your Device when you visit the Sites.
                Your Device sends these bits of text back to the Sites every
                time you visit, so it can recognise you and tailor what you see
                on the screen.
              </p>
              <p>3.3. We use API tokens and keys in the App to:</p>
              <p>
                3.3.1. identify and remember your Login so that you do not need
                to re-enter your Login every time you use the App; and
              </p>
              <p>
                3.3.2. analyse and improve your experience of the App, for
                example to determine what pages, features and links are popular
                and which ones don't get used so much, which helps us keep the
                App relevant and up to date.
              </p>
              <p>
                3.4. We use Google Analytics cookies in the Website to analyse
                and improve your experience of the Website and help us keep the
                Website relevant and up to date, for example to determine what
                pages, features and links are popular and which ones don't get
                used so much. The information collected by these cookies may
                consist of your Internet browser type, operating system, the
                parts of the Website you viewed, the time you viewed them, your
                approximate geographic location, and what other webpages you
                looked at before viewing the Website. All information these
                cookies collect is aggregated and therefore anonymous. As such
                the information collected can’t generally be used to identify
                you personally, but if we can link such information to you then
                we treat it in accordance with this Privacy and Cookies Policy.
                By using the Website, you agree that we can place these types of
                cookies on your Device.
              </p>
              <p>
                3.5. You can disable the use of cookies and related technologies
                on your Device by adjusting the settings in your web browser or
                on your Device. Please note some parts of the Sites may not
                function if you do so. For more information, please see the{" "}
                <a href="http://ico.org.uk/for_the_public/topic_specific_guides/online/cookies">
                  ICO website
                </a>{" "}
                or the IAB’s{" "}
                <a href="http://www.youronlinechoices.com/uk/">
                  Your Online Choices
                </a>
                .
              </p>

              <h2>
                <a name="4"></a>4. How We Use Your Information
              </h2>
              <p>
                4.1. We use information held about you in a number of ways, in
                general to help us provide services to you or improve the way we
                operate. We will only process your information if there is a
                lawful basis for doing so.
              </p>
              <p>
                4.2. We will only process (including collecting, using,
                disclosing and storing) your information where there is a lawful
                basis for doing so. This will be the case if:
              </p>
              <p>4.2.1. We have your consent to process your information</p>
              <p>
                4.2.2. It is necessary for the performance of a contract with
                you or for taking steps prior to entering such a contract that
                we process your information
              </p>
              <p>
                4.2.3. We have a legal obligation to process your information
              </p>
              <p>
                4.2.4. We have a legitimate interest in processing your
                information. To find out further information about how we
                balance our legitimate interests with your right to data privacy
                please <a href="/contact.html">contact us</a>.
              </p>
              <p>
                4.3. We will process your information for the following
                purposes:
              </p>
              <p>
                4.3.1. to provide the TruBe Services, including to process any
                Bookings or cancellations, communicate your ratings, comments,
                feedback and scores to the relevant Service Providers or Users
                (as applicable), and assist with resolving complaints in
                relation to Bookings;
              </p>
              <p>
                4.3.2. to notify you of any changes to the Sites, respond to any
                communication or request from you and remind you of upcoming
                Bookings. Such notifications may be by email and/or SMS, and we
                may also send you push notifications through the App;
              </p>
              <p>
                4.3.3. to ask you to submit ratings, comments, feedback and/or
                an overall score for the User or Service Provider in respect of
                Bookings you have attended;
              </p>
              <p>
                4.3.4. to invite you to participate in surveys and competitions;
              </p>
              <p>
                4.3.5. to administer the Sites and for internal operations,
                including troubleshooting, data analysis, testing, research,
                statistical and survey purposes;
              </p>
              <p>
                4.3.6. to improve the Sites, including to ensure the content on
                the Sites is presented in the most effective manner for you and
                your Device;
              </p>
              <p>
                4.3.7. to allow you to participate in interactive features of
                the Sites, when you choose to do so;
              </p>
              <p>
                4.3.8. to make payments to you, if you are a Service Provider;
              </p>
              <p>
                4.3.9. as part of our efforts to keep the Sites safe and secure;
                and
              </p>
              <p>
                4.3.10. to provide you with information about products and
                services that may be of interest to you. We will only contact
                you by electronic means (e-mail or SMS) with information about
                the Sites and Service Provider Services which are similar to
                those which were the subject of a previous Booking you made or
                negotiated. If you do not want us to contact you in this way,
                please opt-out by ticking the relevant box on the registration
                form or by following the unsubscribe instructions provided with
                every e-mail or SMS we send you. If you are a new User, we will
                contact you by electronic means to provide you with information
                about products and services only if you have consented to this.
                You may withdraw such consent at any time by{" "}
                <a href="/contact.html">contacting us</a> and/or by changing
                your marketing preferences within the Sites.
              </p>

              <h2>
                <a name="5"></a>5. How We Share Your Information
              </h2>
              <p>
                We may share your personal information with any member of our
                group, which means our subsidiaries, our ultimate holding
                company and its subsidiaries, as defined in section 1159 of the
                UK Companies Act 2006.
              </p>
              <p>
                We may also share your information with third parties in some
                circumstances. Please note that when we share your information
                with a Service Provider, they will be the Controller of that
                information, and we are not responsible for their use of the
                information (although we do take steps to ensure that Service
                Providers maintain high standards of data protection).
              </p>
              <h3 class="h5">5.1. When a Booking is made</h3>
              <p>
                When you make a Booking using the App, we will share information
                about you with the relevant Service Provider so that the
                requested Service Provider Service can be provided, including
                your name, gender, your mobile telephone number, details of the
                Service Provider Service you have requested, the Appointment
                Time, the location where the Booking will take place, any
                information which is available as part of your profile on the
                App (including any sensitive personal data you have chosen to
                provide (for example, relevant information about your health and
                fitness) and your photograph), your age-range, and details of
                your average score and performance history if you have made
                previous Bookings.
              </p>
              <p>
                If you are a Service Provider, we will share information about
                you with Users including your full name, gender, any information
                available on your profile within the App and your photograph. We
                will also share your mobile telephone number to any User who
                makes a Booking with you.
              </p>

              <h3 class="h5">5.2. When there is a complaint about a Booking</h3>
              <p>
                In the unlikely event that you have a complaint in relation to a
                Booking, we may also share some or all of the following
                information with the Service Provider (as applicable to the
                nature of your complaint): your name, your mobile telephone
                number, your email address, details of the Service Provider
                Service you requested, the Appointment Time and location for the
                Booking, and/or details of your complaint (which may include any
                relevant sensitive personal data that you have chosen to
                provide).
              </p>
              <p>
                If you are a Service Provider we may share with the User who has
                made the complaint any information we hold about you which we
                consider to be relevant to the complaint.
              </p>

              <h3 class="h5">
                5.3. Ratings, comments, submissions, feedback and scores
              </h3>
              <p>
                If you provide a rating and/or comments about a Service
                Provider, we may share such rating and/or comments with the
                Service Provider.
              </p>
              <p>
                If you are a Service Provider, we may also share the feedback
                and overall score you provide about a User who has made a
                Booking with the relevant User.
              </p>
              <p>
                Third parties (including other Users and Service Providers) may
                also view and use any Submissions you submit to the Sites.
              </p>
              <p>
                We cannot control how third parties may use ratings, comments,
                feedback, scores and/or Submissions, and this Privacy and
                Cookies Policy does not apply to such third party use.
              </p>
              <p>
                Please therefore ensure that your ratings, comments,
                Submissions, feedback and scores (as applicable) do not contain
                any information that you do not want to be seen by the relevant
                third parties.
              </p>

              <h3 class="h5">
                5.4. Third parties providing services on our behalf
              </h3>
              <p>
                We engage a third party to process payments from Users on our
                behalf. In addition, we may engage third parties to perform
                other functions on our behalf, such as carrying out credit
                reference checks, hosting or operating the Sites, sending e-mail
                communications, and data analysis. We may share your information
                with such third parties, including any information which is
                available as part of your profile on the App (including where
                reasonably necessary to do so, any sensitive personal data you
                have chosen to provide (for example, relevant information about
                your health and fitness)). We ask such parties to maintain
                appropriate security measures to protect such information from
                unauthorised access or processing.
              </p>

              <h3 class="h5">
                5.5. When you agree to have your information shared
              </h3>
              <p>
                While on the Sites, you may be given the opportunity to opt-in
                to receive information and/or marketing offers from third
                parties or to otherwise consent to the sharing of information
                about you with a third party. If you agree to have information
                about you shared, such information will be disclosed to the
                relevant third party and will be subject to the privacy policy
                and business practices of that third party.
              </p>

              <h3 class="h5">5.6. Legal and other disclosures</h3>
              <p>
                We may transfer and disclose information about you (which may
                include sensitive personal data we hold about you, if reasonably
                required) to third parties if:
              </p>
              <ul>
                <li>
                  we sell or buy any business or assets, in which case we may
                  disclose information about you to the prospective seller or
                  buyer of such business or assets;
                </li>
                <li>
                  TruBe or substantially all of its assets are acquired by a
                  third party, in which case information held by it about Users
                  will be one of the transferred assets;
                </li>
                <li>
                  we are under a duty to disclose or share information about you
                  in order to comply with Applicable Laws or a lawful request
                  from any governmental or regulatory authority; in order to
                  enforce or apply the <a href="/terms">Terms</a> or any other
                  agreement between you and TruBe; to investigate potential
                  breaches of such Terms or other agreement(s); or to protect
                  the rights, property, safety and/or security of TruBe or of
                  any third party (including other Users and Service Providers).
                  This includes exchanging information with other companies and
                  organisations for the purposes of fraud protection and credit
                  risk reduction.
                </li>
              </ul>

              <h2>
                <a name="6"></a>6. Where We Transfer Your Information
              </h2>
              <p>
                6.1. We or any of the parties with whom we share information
                about you (including any sensitive personal data we hold about
                you) may transfer information about you to, and store it at, a
                destination outside the EEA. These locations may not ordinarily
                provide the same level of data protection as your home country,
                but we will take steps to maintain a level of protection in
                accordance with this policy.
              </p>
              <p>
                6.2. For example, your information may be processed by staff
                operating outside the EEA who work for us or for one of our
                suppliers. These staff may be engaged in the processing of
                Bookings, cancellations and payments and the provision of
                support services. In addition, the servers used to host our
                Sites may be located outside the EEA.
              </p>
              <p>
                6.3. By using the Sites, you agree to such transfer, storage and
                processing. We will take all steps reasonably necessary to
                ensure that your information is treated securely and in
                accordance with this Privacy and Cookies Policy.
              </p>
              <p>
                6.4. All information you provide or make available to us
                (including any sensitive personal data we hold about you) is
                stored on our secure servers.
              </p>
              <p>
                6.5. Any payment transactions carried out by our third party
                provider of payment processing services will be securely
                encrypted.
              </p>
              <p>
                6.6. We may store data, including personal data, on your Device
                using application data caches, browser web storage and other
                technology in order to improve your use of the Sites and to
                shorten the time it takes to load the Sites each time you access
                them.
              </p>

              <h2>
                <a name="7"></a>7. What We Do To Keep Your Information Secure
              </h2>
              <p>
                7.1. Once we have received your information, we will use
                appropriate technical and organisational measures to help
                protect and secure your information.
              </p>
              <p>
                7.2. However, no electronic data transmission or storage of
                information can be guaranteed to be 100% secure. We cannot
                guarantee the security of your information when it is being
                transmitted to the Sites; any transmission is at your own risk.
              </p>

              <h2>
                <a name="8"></a>8. Data Retention
              </h2>
              <p>
                9.1. Where you have consented to receive marketing in accordance
                with section 5.1.10 above, you have the right to ask us not to
                continue using your information for marketing purposes. You can
                exercise the right at any time by{" "}
                <a href="/contact.html">contacting us</a> and/or by opting out
                of marketing using the settings within the Sites.
              </p>
              <p>
                9.2. You have the right to access information we hold about you.
                We may charge you a small fee to process your request to receive
                such information. The fee we charge will not exceed any limit
                set by Applicable Laws. If you would like a copy of some or all
                of such information, please{" "}
                <a href="/contact.html">contact us</a>.
              </p>
              <p>
                9.3. You may ask us to correct or remove information about you
                that you think is inaccurate by{" "}
                <a href="/contact.html">contacting us</a>.
              </p>
              <p>
                9.4. We hope you will be happy with the way we handle your
                information but if not you have the right to complain to the
                Information Commissioner – for more information visit{" "}
                <a href="http://www.ico.gov.uk/">http://www.ico.gov.uk/</a>.
              </p>

              <h2>
                <a name="9"></a>9. Your Rights
              </h2>
              <p>
                9.1. You may have certain rights in respect of the personal
                information about you that we hold and/or use. You can find out
                more about these rights from the Information Commissioner’s
                Office. If you wish such rights please{" "}
                <a href="/contact.html">contact us</a>.
              </p>
              <p>
                9.2. You have the right to be informed about how we collect and
                use your data. This is why we are providing this privacy policy.
              </p>
              <p>
                9.3. You have the right to access information we hold about you.
                If you would like a copy of some or all of such information,
                please <a href="/contact.html">contact us</a>.
              </p>
              <p>
                9.4. You may ask us to correct information about you that you
                think is inaccurate by <a href="/contact.html">contacting us</a>
                .
              </p>
              <p>
                9.5. You have the right to the erasure of your data (also known
                as the right to be forgotten). This means that we have may to
                delete or remove the data we hold about you.
              </p>
              <p>
                9.6. You have the right to restrict our processing of your data.
                This means that you can “block” or “suppress” certain forms of
                processing, while we will still store your information.
              </p>
              <p>
                9.7. You have the right to object to our processing of your
                information.
              </p>
              <p>
                9.8. You have right to obtain and reuse your information in an
                accessible format for your own purposes across different
                services (this is known as “the right to data portability”).
              </p>
              <p>
                9.9. Where we rely on your consent as the lawful basis upon
                which to process your data, you can choose to withdraw that
                consent at any time. This will however not make unlawful any
                processing we have carried out while we still had your consent.
                You can exercise the right at any time by{" "}
                <a href="/contact.html">contacting us</a> and/or by opting out
                of marketing using the settings within the Sites.
              </p>
              <p>
                9.10. We hope you will be happy with the way we handle your
                information but if not you have the right to complain to the
                Information Commissioner – for more information visit{" "}
                <a href="http://www.ico.gov.uk/">http://www.ico.gov.uk/</a>.
              </p>

              <h2>
                <a name="10"></a>10. Contact Us
              </h2>
              <p>
                If you have any questions, comments and requests regarding this
                Privacy and Cookies Policy, please{" "}
                <a href="/contact.html">contact us</a>.
              </p>

              <h2>
                <a name="11"></a>11. Changes to This Privacy Policy
              </h2>
              <p>
                11.1. Any changes we may make to our Privacy and Cookies Policy
                in the future will be posted on this page and, where
                appropriate, notified to you when you next use the App or the
                Website. The new Privacy and Cookies Policy may be displayed
                on-screen and you may be required to read and accept it to
                continue using the Sites and/or the TruBe Service.
              </p>

              <p>
                This Privacy and Cookies Policy was last updated on 24 May 2018.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
