/**
 * Terms & Conditions Page
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/12/10
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useCallback, useState } from "react";
import { Title, Meta } from "react-meta-elements";

// Components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function TermsPage() {
  return (
    <>
      <Title>Terms of Use and Booking</Title>
      <Meta
        name="description"
        content={`On-demand, in person, personal training in London.`}
      />
      <Header showLinks={true} />

      <div className="tbk-bg-grey-light tbk-pb-5">
        <div className="tbk-mb-5 tbk-flex tbk-h-32 tbk-items-center tbk-justify-center tbk-bg-trust-safety-page-head-mobile tbk-bg-cover tbk-bg-no-repeat tbk-text-center md:tbk-h-44 md:tbk-bg-trust-safety-page-head">
          <h1 className="tbk-text-h2-black md:tbk-text-epic-bold tbk-text-basic-white">
            Terms of Use and Booking
          </h1>
        </div>

        <div class="tbk-container tbk-mx-auto tbk-px-2 tbk-pb-7 lg:tbk-px-7">
          <div class="row">
            <aside class="col col-12">
              <h1>Terms of Use and Booking</h1>

              <p>
                These terms and conditions (the "<strong>Terms</strong>") are
                the terms on which we (i) provide the App, Website and TruBe
                Service (each as defined below) to you including any updates or
                supplements to the App, Website or TruBe Service, unless such
                updates come with separate terms, in which case those terms
                apply, and (b) permit you to make Bookings (as defined below).
                If you invite other people to train with you then you are
                responsible for those people and agree that you will ensure that
                they adhere to these Terms.
              </p>

              <p>
                Please read these Terms carefully before downloading the App
                and/or using the TruBe Service or Website. By accessing the App
                and/or the Website, you are agreeing to comply with these Terms
                (which shall include any App Store Rules (as defined below).
              </p>

              <p>
                <strong>Operating system requirements:</strong> The App requires
                an iOS 8 iPhone device and Internet access. You are responsible
                for making any arrangements necessary for you to have access to
                the App and Website, including a connection to the Internet. You
                should be aware that use of the App and Website may impact your
                data usage or the charges to which you are subject under your
                mobile phone contract.
              </p>

              <p>
                <strong>Please note:</strong> You must be aged 18 or over to use
                the App, Website and the TruBe Service. If you are under 18 or
                if you do not agree to these Terms, please do not use the App or
                Website or register as a user of the TruBe Service. If you
                invite other people to train with you then you are responsible
                for ensuring that they are aged 18 or over.
              </p>

              <p>
                You should keep a copy of these Terms for future reference.
                Click <a href="javascript:print();">here</a> to print or
                download.
              </p>

              <p>
                These Terms, the App, Website and TruBe Service are only
                available in the English language.
              </p>

              <p>
                The information that you provide about yourself to TruBe will
                only be used in accordance with our{" "}
                <a href="/privacy">Privacy and Cookies Policy</a>. The Privacy
                and Cookies Policy does not apply to Third Party Sites (as
                defined below).
              </p>

              <h2>1. Capitalised Terms Used In These Terms</h2>

              <p>
                1.1. Capitalised words and phrases in these Terms shall have the
                following meanings:
              </p>

              <p>
                "<strong>App</strong>" means the TruBe mobile application;
              </p>
              <p>
                "<strong>Appointment Time</strong>" means the specific date and
                time for a Booking;
              </p>
              <p>
                "<strong>App Store Rules</strong>" means any rules or policies
                applied by the app store provider or operator from whose site or
                platform you downloaded the App;
              </p>
              <p>
                "<strong>Booking</strong>" means a booking you have made using
                the App for a Service Provider Service;
              </p>
              <p>
                "<strong>Cancellation Fee</strong>" has the meaning set out in
                Clause 11.3;
              </p>
              <p>
                "Confirmed Booking" means a booking you have made using the App
                that is confirmed with a Service Provider and is complete in
                accordance with clause 9.5 below;
              </p>
              <p>
                "<strong>Content</strong>" means the content and materials on
                the App;
              </p>
              <p>
                "<strong>Device</strong>" means the mobile telephone or handheld
                device onto which the App has been downloaded;
              </p>
              <p>
                "<strong>Events Beyond Our Control</strong>" means events beyond
                our reasonable control, including without limitation fire,
                storm, flood, riot, civil disturbance, war, nuclear accident,
                terrorist activity and Acts of God;
              </p>
              <p>
                "<strong>Login</strong>" means (i) any registered user name and
                password accepted by TruBe; or (ii) your Facebook login details,
                either of which you may choose to use to register for and log in
                to the App;
              </p>
              <p>
                "Services" means the fitness services that the Service Provider
                provides;
              </p>
              <p>
                "<strong>Service Fee</strong>" means the amount payable by you
                in respect of a Booking, as indicated on the App at the time you
                make a Booking;
              </p>
              <p>
                "<strong>Service Provider</strong>" means a third party provider
                of a Service Provider Service;
              </p>
              <p>
                "<strong>Service Provider Service</strong>" means any service
                provided by a Service Provider which is available to book
                through the App;
              </p>
              <p>
                "<strong>Service Provider Terms</strong>" has the meaning given
                in Clause 3.2.2;
              </p>
              <p>
                "<strong>Submission</strong>" has the meaning given in Clause
                12.3;
              </p>
              <p>
                "<strong>TruBe Service</strong>" means all or any of the TruBe
                services provided to Users including the ability to register for
                a User account, the provision of information on the App and
                Website, and the ability to book Service Provider Services using
                the App;
              </p>
              <p>
                "TruPlan" means an offer that may be provided by the App from
                time to time to acquire several Bookings that will be carried
                out within several number of session during a specified period
                of time;
              </p>
              <p>
                "<strong>we/us/our</strong>" means Iwoo Limited, trading as
                TruBe, including where appropriate our employees and authorised
                agents;
              </p>
              <p>
                "<strong>Website</strong>" means the TruBe website at
                trubeapp.com;
              </p>
              <p>
                "<strong>Working Days</strong>" means a day which is not a
                Saturday, Sunday or a Bank Holiday in the UK; and
              </p>
              <p>
                "<strong>User</strong>" means any person who uses the App,
                including a person who uses the App for the purpose of making a
                Booking, and any Service Provider who uses the App in connection
                with the provision of Service Provider Services.
              </p>

              <p>
                1.2. Any words following the terms "including", "include", "in
                particular" or "for example" or any similar phrase shall be
                construed as illustrative and shall not limit the generality of
                the related general words.
              </p>

              <h2>2. Introduction and About Us</h2>
              <p>
                2.1. The App and Website are owned and operated by Iwoo Limited,
                a company registered in England and Wales under registration
                number 09042155, with its registered office address at 5 Elstree
                Gate, Elstree Way, Borehamwood, Hertfordshire, WD6 1JD, and
                trading as TruBe ("<strong>TruBe</strong>").
              </p>
              <p>
                2.2. The App allows you to make Bookings for Service Provider
                Services. We provide you with information about the Service
                Provider Services within the App.
              </p>
              <p>
                2.3. If you have any questions, complaints or comments about the
                App, Website or TruBe Service then please{" "}
                <a href="/contact.html">contact us</a>.
              </p>
              <p>2.4. Our VAT number is GB 189914939.</p>
              <p>
                Iwoo Limited is registered as a data controller for the purposes
                of the Data Protection Act 1998. Our registration number on the
                register of data controllers maintained by the Information
                Commissioner is <strong>ZA089757</strong>. If you have any
                queries about how we use your personal data please contact us.
                Please read our{" "}
                <a href="/privacy">Privacy and Cookies Policy</a>.
              </p>

              <h2>3. Application of These Terms</h2>
              <p>
                3.1. Subject to Clauses 3.2 and 3.3, these Terms apply to your
                use of the App, Website and TruBe Service and booking of Service
                Provider Services.
              </p>
              <p>
                3.2. We may make different versions of the App available as
                follows:
              </p>
              <p>
                3.2.1. to Users wishing to use the App to make Bookings, in
                which case these Terms shall apply in full; and/or
              </p>
              <p>
                3.2.2. to Service Providers to enable them to manage their
                profile on the App, Bookings and payments, in which case these
                Terms (except for Clauses 9 to 13 inclusive) shall apply in
                addition to the Service Provider’s separate agreement with TruBe
                in relation to the provision of Service Provider Services ("
                <strong>Service Provider Terms</strong>"). In the event of any
                conflict between these Terms and the Service Provider Terms, the
                Service Provider Terms shall take priority.
              </p>
              <p>
                3.3. If any open-source software is included in the App, Website
                or any part of the TruBe Service, the terms of the applicable
                open-source licence(s) will apply in addition to these Terms,
                and will override these Terms in the event of a conflict. A list
                of the open source software used by TruBe, and details of the
                applicable licence terms, is available here.
              </p>

              <h2>
                4. Access to and Use of the App, Website and TruBe Service
              </h2>
              <p>
                4.1. In consideration of you agreeing to abide by the Terms, we
                grant you a non-transferable, non-exclusive licence to use the
                App on your Device and the Website, subject to these Terms, the
                Privacy and Cookies Policy and the App Store Rules.
              </p>
              <p>
                4.2. You may download a copy of the App onto your Device and
                view, use and display the App on your Device for your personal
                purposes only (which may be commercial if you are using the App
                as a Service Provider). If you do not own the Device you
                download the App onto, you shall obtain permission from the
                owner(s) of the Device before downloading the App onto that
                Device.
              </p>
              <p>
                4.3. You acknowledge that you (or the owner of the Device) may
                be charged by your (or their) service providers for Internet
                access when downloading and using the App or accessing the
                Website. You accept responsibility for the use of this App,
                Website and the TruBe Service on or in relation to any Device
                which you use, whether or not it is owned by you.
              </p>
              <p>
                4.4. Except as expressly set out in these Terms or as otherwise
                agreed by TruBe, you agree:
              </p>
              <p>
                4.4.1. not to copy the App, Website, TruBe Service or the
                Content in whole or in part except where such copying is
                incidental to normal use of the App or Website, or where it is
                necessary for the purpose of back-up or operational security;
              </p>
              <p>
                4.4.2. not to rent, lease, sub-license, loan, translate, merge,
                adapt, vary, alter or modify the App, Website, TruBe Service or
                the Content in whole or in part;
              </p>
              <p>
                4.4.3. (except if you are a Service Provider) not to use the
                App, Website, TruBe Service or any Content, or to leave a
                rating, comment or review or make any Submission, for any
                commercial or non-personal purposes;
              </p>
              <p>
                4.4.4. not to sell, resell or otherwise trade any Booking or
                Service Provider Services to or with any third party, or make or
                accept a Booking on behalf of a third-party except that you may
                invite friends to train with you if we agree to this or make
                this functionality available on the App. Any attempt to do any
                of the foregoing may, at our discretion, void the relevant
                Booking;
              </p>
              <p>
                4.4.5. not to permit the App or Website or any part of it to be
                combined with, or become incorporated in, any other programs;
              </p>
              <p>
                4.4.6. not to disassemble, decompile, reverse-engineer or create
                derivative works based on the whole or any part of the App or
                Website or attempt to do any such thing except where such
                actions are essential for the purpose of achieving the
                inter-operability of the App or Website with another software
                program, and provided that the information obtained by you
                during such activities:
              </p>
              <p>
                (i) is used only for the purpose of achieving inter-operability
                of the App or Website with another software program;
              </p>
              <p>
                (ii) is not unnecessarily disclosed or communicated without our
                prior written consent to any third party; and
              </p>
              <p>
                (iii) is not used to create any software that is substantially
                similar to the App or Website;
              </p>
              <p>
                4.4.7. not to provide or otherwise make available the App in
                whole or in part in any form to any person;
              </p>
              <p>
                4.4.8. not to impersonate another individual when using the App,
                the Website, the TruBe Service or any Service Provider Service;
              </p>
              <p>
                4.4.9. not to download or access the App or Website or make a
                Booking unless you are aged eighteen or over;
              </p>
              <p>
                4.4.10. that all information and details provided by you to us
                (including on registration) are true, accurate and up to date in
                all respects and at all times. You can update or correct your
                personal details at any time by using the{" "}
                <strong>Edit My Profile</strong> function which is available on
                the App;
              </p>
              <p>
                4.4.11. that you will only use the App, the Website, TruBe
                Service and the Service Provider Services and make Bookings for
                lawful purposes and in accordance with these Terms;
              </p>
              <p>
                4.4.12. that in relation to any Submissions provided by you to
                use or uploaded by you to the App or Website, you have the right
                to do so and have obtained all necessary permissions and/or
                approvals;
              </p>
              <p>
                4.4.13. not to publish, email, upload, transmit or otherwise
                disseminate any Submission, rating or review which is
                misleading, false, inaccurate, defamatory, obscene, indecent or
                which infringes copyright or other rights of third parties or
                may have the effect of being harassing, threatening, libellous
                or abusive or which is otherwise unlawful;
              </p>
              <p>
                4.4.14. not to infringe our intellectual property rights or
                those of any third party; and
              </p>
              <p>4.4.15. not to use the App, Website or TruBe Service:</p>
              <p>
                4.4.15.1. in any way that is unlawful or fraudulent, or has any
                unlawful or fraudulent purpose or effects or promotes such
                purposes;
              </p>
              <p>
                4.4.15.2. in any way that interrupts, damages, impairs or
                renders the App, TruBe Service or Website less efficient;
              </p>
              <p>
                4.4.15.3. in a way that could damage, disable, overburden,
                impair or compromise our systems or security or interfere with
                other users;
              </p>
              <p>
                4.4.15.4. to collect or harvest any information or data from the
                App or Website or our systems or attempt to decipher any
                transmissions to or from the servers running the App or Website;
              </p>
              <p>
                4.4.15.5. to transfer files that contain viruses, trojans or
                other harmful programs;
              </p>
              <p>
                4.4.15.6. to access or attempt to access the accounts of other
                Users or to penetrate or attempt to penetrate the App or Website
                security measures;
              </p>
              <p>
                4.4.15.7. to advertise or promote third party or (except if you
                are a Service Provider) your own products or services, including
                by sending or procuring the sending of, any unsolicited or
                unauthorised advertising or promotional material or any other
                form of similar solicitation; or
              </p>
              <p>
                4.4.15.8. for the purpose of harming or attempting to harm
                minors in any way.
              </p>
              <p>
                4.5. By using the App, Website and TruBe Service you acknowledge
                and agree that Internet transmissions are never completely
                private or secure. You understand that any message or
                information you send using the App or Website may be read or
                intercepted by others, even if there is a special notice that a
                particular transmission is encrypted.
              </p>
              <p>
                4.6. You acknowledge that the App, Website and TruBe Service
                have not been developed to meet your individual requirements.
              </p>

              <h2>5. Availability, Changes and Updates</h2>
              <p>
                5.1. We do not guarantee the availability of the App, Website,
                TruBe Service and/or any Service Provider Services.
              </p>
              <p>
                5.2. We reserve the right to withdraw the App, Website and/or
                TruBe Service (in whole or in part) or amend any part of it
                (including but not limited to the Content, Service Provider
                Services and the TruBe Service), for legal, regulatory or
                security reasons or due to a change in market conditions which
                affects our ability to provide the App, Website or the TruBe
                Service or make available any Service Provider Service to you.
              </p>
              <p>
                5.3. From time to time updates to the App may be issued through
                the relevant App Store. Depending on the update, you may not be
                able to use the App or the TruBe Service until you have
                downloaded the latest version of the App and accepted any new
                terms.
              </p>
              <p>
                5.4. TruBe reserves the right, at its discretion, to change
                these Terms for legal, regulatory or security reasons or due to
                a change in market conditions which affects our ability to
                provide the App, Website or TruBe Service or make available any
                Service Provider Service to you. When these Terms are changed,
                TruBe will publish details of the new Terms on the App and the
                Website as updated from time to time and will ask you to agree
                to the new Terms before you can continue using the App. However,
                if we change these Terms to your detriment, you may end your
                agreement with us by stopping use of the App, TruBe Service and
                Website and closing your TruBe account.
              </p>

              <h2>6. Rights Granted and Reserved</h2>
              <p>
                6.1. Except for any Submissions provided by you and except as
                otherwise provided in these Terms:
              </p>
              <p>
                6.1.1. the App, the Website and all Content belongs to TruBe or
                its licensors. Your use of the App and the Website grants no
                rights to you in relation to the intellectual property rights in
                the App, Website or the Content (including, without limitation,
                copyright, trade marks, logos, graphics, photographs,
                animations, videos and text or the intellectual property of
                third parties); and
              </p>
              <p>
                6.1.2. you may not copy, reproduce, republish, download, post,
                broadcast, record, transmit, commercially exploit, edit,
                communicate to the public or distribute in any way the App,
                Website or Content or the computer codes of elements comprising
                the App and Website other than for your personal, non-commercial
                use. Modification of the Content or other prohibited use of the
                Content is a violation of TruBe’s or its licensors’ copyright
                and other proprietary rights. All trademarks, service marks and
                trade names displayed on the App and Website are proprietary to
                TruBe or its licensors.
              </p>
              <p>
                6.2. You acknowledge that you have no right to have access to
                the App in source-code form.
              </p>

              <h2>7. Links To and From Other Websites</h2>
              <p>7.1. You may establish links to the Website provided:</p>
              <p>7.1.1. you link only to the home page of the Website;</p>
              <p>
                7.1.2. you do not remove or obscure advertisements, the
                copyright notice or other notices on the Website; and
              </p>
              <p>
                7.1.3. you stop providing links to the Website immediately if we
                require you to.
              </p>
              <p>
                7.2. The App and/or the Website may contain links to other
                websites provided by third parties ("Third Party Sites"), either
                directly or through frames. You acknowledge that:
              </p>
              <p>
                7.2.1. these links are provided for your ease of reference and
                convenience only;
              </p>
              <p>
                7.2.2. TruBe does not control and is not responsible for the
                availability or content of Third Party Sites;
              </p>
              <p>
                7.2.3. our inclusion of links does not imply any endorsement of
                the material contained in such Third Party Sites or any
                association with their operators;
              </p>
              <p>
                7.2.4. we will not be a party to, or in any way responsible for,
                any transaction which you may enter into using any Third Party
                Sites; and
              </p>
              <p>
                7.2.5. except where expressly set out in these Terms, we shall
                not be liable to you in respect of any loss or damage which you
                may suffer by using any Third Party Sites and you agree that you
                will not involve us in any dispute between you and any Third
                Party Site.
              </p>

              <h2>8. Registration and Logins</h2>
              <p>
                8.1. In order to access the App, you will be required to
                register a Login with TruBe.
              </p>
              <p>
                8.2. To complete registration for an account, you must provide
                TruBe with any mandatory information which is requested. For
                more information please see TruBe’s{" "}
                <a href="/privacy">Privacy and Cookies Policy</a>.
              </p>
              <p>
                8.3. You promise that all information provided by you to TruBe
                or any Service Provider (whether on registration or a later
                date) shall be accurate and complete.
              </p>
              <p>
                8.4.It is your responsibility to inform TruBe of any changes to
                your information (including in particular to your email address
                and payment details) by using the "Edit My Profile" function
                available on the App.
              </p>
              <p>
                8.5. You should treat your Login as confidential and you are
                responsible for maintaining the confidentiality of your Login
                and for all activities that are carried out under the Login.
                Each registration is for a single user only.
              </p>
              <p>
                8.6. TruBe does not permit any of the following, and you agree
                that you shall comply with such rules:
              </p>
              <p>8.6.1. any other person sharing your Login;</p>
              <p>
                8.6.2. access through a single Login being made available to
                multiple users on a network;
              </p>
              <p>8.6.3. creation of multiple Logins by you; or</p>
              <p>
                8.6.4. selection or use of a Login with the intent of
                impersonating another person.
              </p>
              <p>
                8.7. We do not have the means to check the identities of people
                using the App. You are responsible for, and will be liable to
                TruBe for all use of the App made by you or anyone else using
                your Login (including, without limitation, in connection with
                any Bookings made using your Login) and for preventing
                unauthorised use of your Login. You agree to{" "}
                <a href="/contact.html">contact</a> TruBe immediately if you
                believe or suspect that there has been any breach of security
                (e.g. the disclosure, theft or unauthorised use of your Login or
                any payment information). TruBe will not be liable to you or any
                third party for unauthorised use of your Login unless this is
                due to our negligence.
              </p>

              <h2>9. Bookings and Service Provider Services</h2>
              <p>
                9.1. The App provides you with information about, and the
                ability to book and pay for, various Service Provider Services.
              </p>
              <p>
                9.2. Once registered as a User, you may view information
                regarding the Service Provider Services and make a Booking for
                Service Provider Services using the App.
              </p>
              <p>
                9.3. The App will keep a record of all Bookings made by you
                using the App.
              </p>
              <p>
                9.4. You are solely responsible for considering whether, and
                ensuring that, you are sufficiently fit and healthy to take
                advantage of the Service Provider Services you book using the
                App. You are also solely responsible for ensuring that any
                people that you invite to train with you are sufficiently fit
                and healthy to take advantage of the Service Provider Services
                you book using the App.
              </p>
              <p>
                9.5. All Bookings are subject to confirmation by us and your
                agreement for a Booking is not complete until your booking
                request is confirmed within the App.
              </p>
              <p>
                9.6. In order to confirm your Booking and/or to remind you of
                your Booking at a later date, you may receive emails and/or SMS
                messages from TruBe and/or in-App notifications.
              </p>
              <p>9.7. Once your Booking has been confirmed:</p>
              <p>
                9.7.1. we will allocate you a Service Provider and provide you
                with information about that Service Provider in advance of your
                Booking; and
              </p>
              <p>
                9.7.2. we ask you to attend the Booking at the Appointment Time.
                Your Booking will not be valid for any Service Provider other
                than the Service Provider we have allocated to you in accordance
                with this Clause 9, any other Service Provider Service, or any
                other date or time. If you want to cancel your Booking, Clause
                11 applies.
              </p>
              <p>
                9.8. Sometimes the Service Provider who has been allocated to
                your Booking will need to cancel. You agree that we have no
                responsibility for any cancellation of a Booking by a Service
                Provider. However, we will communicate any such cancellation to
                you and use reasonable endeavours to allocate you an alternative
                Service Provider if there is one available. We will only
                allocate you an alternative Service Provider if there is one
                available who meets your preferences (as provided to us at the
                time you made the Booking). You will then be able to confirm the
                Service Provider if there is one available who meets your
                preferences. If we cannot allocate you an alternative Service
                Provider, we will not charge you for the Booking. If you do not
                wish to proceed with a Booking where we have allocated an
                alternative Service Provider, you may cancel the Booking and
                Clause 11 will apply.
              </p>
              <p>
                9.9. While we make an effort to ensure that information
                displayed on the App about Service Providers and Service
                Provider Services is accurate, this information is provided by
                the relevant Service Provider and is and remains their
                responsibility. TruBe does not take responsibility for this
                material or its accuracy.
              </p>
              <p>
                9.10. TruBe may also (but is not obliged to) carry out
                background checks in respect of Service Providers and/or take
                steps to verify that Service Providers have the licences,
                consents, qualifications and insurances that they are required
                to have under applicable laws, regulations and professional
                rules or that they are stated on the App as having.
              </p>
              <p>
                9.11. You agree that each Booking is an agreement between you
                and the Service Provider. TruBe is not a party to such agreement
                and provides no guarantee in relation to the Service Provider’s
                performance of any Booking. Accordingly, you acknowledge that
                the Service Provider (and not TruBe) is responsible for:
              </p>
              <p>
                9.11.1. the performance and quality of the Service Provider
                Services (including any equipment or advice provided by the
                Service Provider during a Booking);
              </p>
              <p>
                9.11.2. obtaining all licences, consents, qualifications and
                insurances that they are required to have under applicable laws,
                regulations and professional rules or that they are stated on
                the App as having; and
              </p>
              <p>
                9.11.3. complying with all applicable laws, regulations and
                professional rules in connection with the Service Provider
                Services.
              </p>
              <p>
                9.12. However, you have certain rights by law in respect of your
                receipt of Service Provider Services, including that the Service
                Provider will provide the Service Provider Services to a
                reasonable standard.
              </p>
              <p>9.13. You agree:</p>
              <p>
                9.13.1. that we may pass on certain information about you to the
                Service Provider in connection with each Booking you make, so
                that the Service Provider can provide the Service Provider
                Services. Please see our{" "}
                <a href="/privacy">Privacy and Cookies Policy</a> for further
                information on what we provide to Service Providers;
              </p>
              <p>
                9.13.2. to pro-actively inform the Service Provider on or before
                commencement of the relevant Service Provider Services of any
                relevant health conditions (such as illnesses or injuries) that
                may affect the provision or your receipt of the Service Provider
                Services or that of any person who you invite to train with you.
                Whilst we may provide some of this information to the Service
                Provider if you have chosen to make it available to us (as
                stated in our <a href="/privacy">Privacy and Cookies Policy</a>
                ), you agree that we are under no obligation to do so;
              </p>
              <p>
                9.13.3. to provide relevant information relating to your health
                and fitness and the health and fitness of any person who you
                invite to train with you where such information is requested by
                the Service Provider before commencement of or during the
                provision of the Service Provider Services; and
              </p>
              <p>
                9.13.4. that you are responsible for wearing suitable clothing,
                bringing water and keeping yourself suitably hydrated during
                each Booking and for ensuring that any person who you invite to
                train with you also does so.
              </p>
              <p>
                9.14. You agree that you shall not (and shall not encourage any
                person who you invite to train with you to):
              </p>
              <p>
                9.14.1. contact a Service Provider for any reason (including to
                book another session with that Service Provider outside of the
                App) after a Booking has completed, except by making another
                Booking with that Service Provider using the App or with our
                prior written consent (which we may withhold at our sole
                discretion); or
              </p>
              <p>
                9.14.2. engage or attempt to engage any Service Provider to
                provide services (other than by making a Booking using the App).
              </p>

              <h2>10. Prices and Payment</h2>
              <p>
                10.1. The Service Fee for any individual Service Provider
                Service is as stated on the App at the time you place your
                Booking, except in the case of obvious error. The Service Fee is
                inclusive of UK VAT.
              </p>
              <p>
                10.2. We try to ensure that all prices on our App are accurate
                but errors may occur. If we discover an error in the Service Fee
                we will inform you as soon as possible and give you the option
                of reconfirming your Booking at the correct price or cancelling
                it. If you cancel no payment will be taken from you.
              </p>
              <p>
                10.3. TruBe accepts payment using the methods specified{" "}
                <a href="">here</a>.
              </p>
              <p>
                10.4. You confirm that any card or payment account you use to
                make a Booking is yours or that you have authorisation from the
                owner of the card or payment account to make such payment.
              </p>
              <p>
                10.5. All credit/debit card holders are subject to validation
                checks and authorisation by the card issuer. You agree that
                payment pre-authorisation checks may be carried out on our
                behalf in respect of an amount equal to the Service Fee when you
                make a Booking. If the issuer of the card or provider of the
                payment service which you are using refuses to authorise payment
                we will not accept your Booking (or if already accepted, we will
                give you notice that your Booking is cancelled) and we will not
                be responsible for any delay or non-performance of the Service
                Provider Services.
              </p>
              <p>
                10.6. Unless you cancel a Booking in accordance with Clause
                11.2, the Service Fee (or where Clause 11.3 applies) the
                Cancellation Fee will be debited from your account upon or
                shortly after the Appointment Time (or if earlier, when you
                cancel the Booking).
              </p>
              <p>
                10.7. We are not responsible for your card issuer, bank or
                payment service provider charging you as a result of our
                processing of payments from you in accordance with these Terms.
              </p>

              <h2 id="cancellation">
                11. Cancellation of Bookings and Refunds
              </h2>
              <p>
                11.1. You have the right to cancel a Booking request any time
                until you have confirmed a booking with a Service Provider.
              </p>
              <p>
                11.2. Once a Booking request has been confirmed, you acknowledge
                that you do not have the right to cancel a Booking under
                statutory regulation. However, you are entitled to cancel
                Bookings in accordance with this Clause 11.2. If you change your
                mind about your Booking prior to the Appointment Time then you
                may cancel your Booking without making payment of the Service
                Fee or any Cancellation Fee provided that you cancel the Booking
                via the App at least twelve (12) hours prior to the start time
                of your Booking; or
              </p>
              <p>
                (ii) if you are making the Booking within such twelve (12) hour
                period, within five (5) minutes of you having confirmed on the
                App that you want to make the Booking ("
                <strong>Grace Period</strong>").
              </p>
              <p>
                11.3. If you use the App to cancel a Booking within twelve (12)
                hours of the start time of your Booking or, where relevant,
                after the Grace Period has elapsed, we will charge you 100% of
                the Service Fee (such amount being inclusive of VAT) ("
                <strong>Cancellation Fee</strong>") as stated in Clause 10.6.
              </p>
              <p>
                11.4. You will be charged the Service Fee, plus VAT, as stated
                in Clause 10.6 if you:
              </p>
              <p>
                11.4.1. cancel a Booking other than as permitted under Clauses
                11.1 and 11.2; or
              </p>
              <p>
                11.4.2. attempt to cancel a Booking on or after the Appointment
                Time; or
              </p>
              <p>
                11.4.3. fail to attend a Booking at the Appointment Time and/or
                at the correct location.
              </p>
              <p>
                11.5. We charge Cancellation Fees and Service Fees as stated in
                Clauses 11.3 and 11.4 to compensate the Service Provider,
                because the Service Provider is unlikely to be able to make
                another Booking where you cancel with short or no notice.
              </p>
              <p>
                11.6. We may waive our rights under Clause 11.3 or 11.4 in our
                sole discretion where you have been unable to cancel a Booking
                without incurring the Cancellation Fee or Service Fee for
                genuine reasons which were outside of your control. Please{" "}
                <a href="/contact.html">contact us</a> if this is the case.
              </p>
              <p>
                11.7. If you attempt to attend a Booking at the relevant
                Appointment Time but the Service Provider has entered
                administration, insolvency, bankruptcy (or any similar situation
                which affects the Service Provider’s ability to provide the
                Service Provider Services) or is no longer in business, or if
                the Service Provider is not available to provide the Service
                Provider Services at the Appointment Time, we will provide you
                with a refund for the Service Fee on request.
              </p>

              <h2>12. Cancellations and Refunds of TruPlans</h2>
              <p>
                12.1. Purchases for TruPlans cannot be refunded, except in the
                following circumstances:
              </p>
              <p>
                (i) If your booking requests are outside of our service area, we
                are currently [providing];
              </p>
              <p>
                (ii) If we fail to provide you with a Service Provider at a
                reasonable time, we will use reasonable endeavors to inform you
                as soon as possible and cancel your order. You will then receive
                a full refund from us;
              </p>
              <p>
                12.2. We will usually refund any money received from you using
                the same payment method originally used by you to pay for your
                purchase.
              </p>
              <p>
                12.3 Any sessions unused in your TruPlan period will not be
                automatically carried over once your TruPlan renews.
              </p>

              <h2>
                13. Ratings, Comments, Complaints, Feedback and Submissions
              </h2>
              <p>
                13.1. After the Appointment Time for a Booking, you may be
                invited to provide a rating and add comments in relation to your
                experience of the Service Provider Service. You may also provide
                feedback or raise any complaints in relation to a Service
                Provider or Service Provider Services by{" "}
                <a href="/contact.html">contacting us</a>. We will attempt to
                resolve any complaints but do not guarantee that we will be able
                to do so. If possible, please raise any complaints within 24
                hours of the Booking so that we can investigate the nature of
                your complaint in the best possible way.
              </p>
              <p>13.2. You agree that:</p>
              <p>
                13.2.1. we may share your ratings, comments and details of any
                complaint with the relevant Service Provider, as further
                described in the{" "}
                <a href="/privacy">Privacy and Cookies Policy</a>; and
              </p>
              <p>
                13.2.2. we may use your rating for a Service Provider to
                calculate and publish an average rating for that Service
                Provider.
              </p>
              <p>
                13.3. The App and/or Website may also enable you to upload other
                material onto the App and/or Website, such as links, comments,
                photos and articles (such material, except where it is provided
                by a Service Provider, shall be referred to as "Submissions".
                Any such material which is provided by a Service Provider is
                subject to the Service Provider Terms rather than these Terms).
              </p>
              <p>
                13.4. By making any Submission via the App and/or Website, you
                grant us the right to use such Submission at our own discretion
                in any media including, without limitation, to edit, copy,
                reproduce, display, disclose, post and remove such Submissions
                from the App and/or Website.
              </p>
              <p>
                13.5. TruBe is under no obligation to display your Submissions
                on the App or Website and reserves the right to remove, edit or
                adapt your Submissions at any time and for any reason.
              </p>
              <p>
                13.6. Where other Users post Submissions on the App, such
                material remains the responsibility of the User who posted them
                and TruBe takes no responsibility for the accuracy or otherwise
                of such material.
              </p>

              <h2>14. Competitions and Promotional Offers</h2>
              <p>
                From time to time we may offer you the chance to enter
                competitions or take up certain promotional offers. We will
                alert you to any additional terms which apply to such
                competitions or promotions at the relevant time.
              </p>

              <h2 id="referral">15. Referral Program</h2>
              <p>
                15.1. You may be able to earn account credit by referring your
                friends to make a Booking via the App (the "Referral Program").
                For each friend you refer to TruBe for which a Booking is
                completed, you may be able to receive TruBe Session Credits.
                "TruBe Session Credits" are "points" issued by TruBe that may be
                applied as credits toward future Confirmed Bookings made by you
                via the App.
              </p>
              <p>
                15.2. TruBe may suspend or terminate the Referral Program at any
                time and for any reason. TruBe will not grant any further
                Session Credits after the suspension or termination, but you
                will be able to keep any Session Credits that you have accrued
                unless you are found to be in breach of these terms, in which
                case, we may revoke any Session Credits that you have earned.
              </p>
              <p>
                15.3. To receive a Session Credit you must share the referral
                code with a friend. Your friend then has to create a TruBe login
                and make a Booking using the App. Once their Booking is
                confirmed by us and your friend has paid the Service Fee, which
                is not subsequently cancelled, we will credit your account with
                a free session. This is known as a "Qualifying Referral". You
                may share the referral code using the App (it will offer some
                options to do it). The time limit for use of the referral code
                as well as rules of using it together with other promotions may
                be changed from time to time by TruBe of which you will be
                informed by the App.
              </p>
              <p>
                15.4. If the friend you refer creates an account in a country
                outside of the United Kingdom you will earn credit in the
                currency of that country. Session Credits may not be transferred
                or exchanged for any cash or money. Credits earned in each
                currency will accrue separately and credits earned in one
                currency cannot be converted into any other currency. Except as
                explicitly provided in these Terms and Conditions, Session
                Credits are non-transferable.
              </p>
              <p>
                15.5. The referral [code/link] should only be shared with your
                friends and should only be used for personal and non-commercial
                purposes. You are not permitted to publish or distribute the
                [code/link] on platforms or media where you are a contributor
                but not the primary content owner (such as Wikipedia, coupon
                websites or Reddit).
              </p>
              <p>
                15.6. Any Session Credits that you earn will be shown in your
                TruBe account in a commercially reasonable amount of time after
                the Qualifying Referral has been completed. The Session Credits
                you earn will be shown [where in the app/Login page]. Session
                Credits can be accrued solely by you and you may not earn
                credits by permitting another individual to use your TruBe
                account. Session Credits accrued in multiple TruBe accounts may
                not be combined into one TruBe account. You may not earn Session
                Credits by creating multiple TruBe accounts. You are responsible
                for keeping track of the Session Credits in your account.
              </p>
              <p>
                15.7. Free sessions cannot be carried over and will expire 30
                days from the date they were issued.
              </p>

              <h2>16. Liability</h2>
              <p>
                16.1. You acknowledge that we have limited control over the
                nature and content of Submissions uploaded by you and other
                Users.
              </p>
              <p>
                16.2. You agree to compensate and defend us fully against any
                claims or legal proceedings brought against us by any other
                person as a result of your breach of these Terms.
              </p>
              <p>
                16.3. If we take legal action against you for non-payment or any
                other breach of these Terms and a court makes an award in our
                favour, you will be responsible for all costs allowable by the
                Courts.
              </p>
              <p>
                16.4. We reserve the right to suspend, restrict or terminate
                your access to the App, Website and the TruBe Service at any
                time without telling you if we have reasonable grounds to
                believe you have breached any of these Terms. This shall not
                limit our right to take any other action against you that we
                consider appropriate to defend our rights or those of any other
                person.
              </p>
              <p>
                16.5. We accept liability for death or personal injury caused by
                our negligence or that of our employees and agents. We do not
                seek to exclude liability for fraudulent misrepresentation by us
                or our employees or agents.
              </p>
              <p>
                16.6. Nothing in these Terms is intended to affect your
                statutory rights. For more information about your statutory
                rights contact your local Citizens Advice Bureau or Trading
                Standards Office.
              </p>
              <p>
                16.7. If we breach these Terms we shall only be liable for
                losses which are a reasonably foreseeable consequence of such
                breach, up to a maximum of the greater of (i) the amount paid by
                you to us for Bookings you have made in the twelve (12) months
                prior to any such loss being incurred by you, and (ii) five
                hundred pounds sterling (£500). Losses are foreseeable where
                they could be contemplated by you and us at the time of entering
                into these Terms.
              </p>
              <p>
                16.8. You agree that TruBe will not be liable to you and/or any
                third party for or in connection with:
              </p>
              <p>16.8.1. losses not caused by our breach of these Terms;</p>
              <p>
                16.8.2. any consequential or incidental losses which are a side
                effect of the main loss or damage and are not reasonably
                foreseeable by you and us at the time of entering into these
                Terms, for example: loss of business, profits, opportunity,
                income or revenue;
              </p>
              <p>
                16.8.3. failure to provide the TruBe Service, Website or the App
                or to meet any of our obligations under these Terms where such
                failure is due to Events Beyond Our Control;
              </p>
              <p>
                16.8.4. temporary or permanent suspension, removal or
                modification of the TruBe Service, Website or the App; or
              </p>
              <p>
                16.8.5. the performance or non-performance of the Service
                Provider Services by the Service Provider, or matters which are
                the responsibility of the Service Provider, including the
                standard and quality at which the Service Provider Services are
                performed, any equipment or advice provided by the Service
                Provider, the Service Provider’s compliance with applicable
                laws, regulations and professional rules, and the Service
                Provider’s responsibility for obtaining and maintaining
                licences, consents, insurances and qualifications.
              </p>

              <h2>17. Termination</h2>
              <p>
                17.1. We may terminate our agreement with you (as set out in
                these Terms) immediately by written notice to you for any reason
                at any time if you breach any term of these Terms.
              </p>
              <p>
                17.2. You may terminate your agreement with us (as set out in
                these Terms) immediately at any time by closing your account,
                uninstalling the App and ceasing to use the App and Website.
              </p>
              <p>17.3. On termination for any reason:</p>
              <p>
                {" "}
                (a) all rights granted to you under these Terms shall cease;
              </p>
              <p>
                {" "}
                (b) if you have not already done so, we will close your TruBe
                account and you must immediately cease all activities authorised
                by these Terms, including your use of the TruBe Service, App and
                Website;
              </p>
              <p>
                {" "}
                (c) if you have not already done so, you must immediately delete
                or remove the App from all Devices and immediately destroy all
                copies of the App then in your possession, custody or control
                and if requested, certify to us that you have done so; and
              </p>
              <p>
                {" "}
                (d) any amounts due from you in respect of Bookings shall remain
                due and payable in accordance with Clauses 10 and/or 11 as
                applicable. If we terminate under Clause 17.1, we will cancel
                any Bookings where the Appointment Time falls on or after the
                termination date. If you terminate under Clause 16.2, you are
                responsible for cancelling any Bookings where the Appointment
                Time falls on or after the termination date, in accordance with
                Clause 11. If you fail to do so, you may be required to pay
                Service Fees, as stated in Clause 11.
              </p>

              <h2>18. Notifying Each Other</h2>
              <p>
                If we need to notify you of any matter in relation to these
                Terms, we will do so by email to the email address which you
                gave when registering (or any updated address provided by you
                via the "Edit My Profile" function). If you need to notify us of
                any matter you should <a href="/contact.html">contact us</a>.
              </p>

              <h2>19. Transfer of This Agreement</h2>
              <p>
                19.1. We may wish to transfer our rights or obligations or
                sub-contract our obligations under these Terms to another legal
                entity. You agree that we may do so provided that:
              </p>
              <p>
                19.1.1. this will not adversely affect the standard of the TruBe
                Service you receive under these Terms; and
              </p>
              <p>
                19.1.2. in the case of transfer only, after we notify you of the
                date on which we will transfer our rights and obligations under
                these Terms to another legal entity, your only rights under or
                in connection with these Terms will be against the new legal
                entity and not against us.
              </p>
              <p>
                19.2. These Terms are personal to you. Except as stated in
                Clause 18.1, you may not transfer any of your rights or
                obligations under these Terms to anyone else.
              </p>

              <h2>20. Law Applying to This Agreement</h2>
              <p>
                If you wish to take court proceedings against us you must do so
                within England. The laws of England and Wales will apply to any
                dispute between TruBe and you in relation to these Terms, and
                between you and a Service Provider in relation to a Booking, the
                Service Provider and/or Service Provider Services.
              </p>

              <h2>21. General</h2>
              <p>
                21.1. These Terms are not intended to give rights to anyone
                except you and us. This does not affect our right to transfer
                these Terms as set out above.
              </p>
              <p>
                21.2. If any term is disallowed or found to be invalid by any
                court or regulator, the other provisions of these Terms shall
                continue to apply.
              </p>
              <p>
                21.3. Headings in these Terms are for convenience only and will
                have no legal meaning or effect.
              </p>
            </aside>

            <aside class="col col-2"></aside>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
