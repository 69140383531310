import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import Modal from "react-modal";
import { Title, Meta } from "react-meta-elements";

import { useAppState } from "../../state";
import { useSignUpState } from "../../state/SignUpState";
import { useRegion } from "../../context/RegionProvider";

// Components
import Header from "../Header/Header";
import Login from "../Login/Login";
import SignUpForm from "../SignUp/SignUpForm";
import SignUpCodeVerification from "../SignUp/CodeVerification/CodeVerification";
import PasswordConfirmForm from "../SignUp/PasswordConfirmForm/PasswordConfirmForm";
import Registration from "../SignUp/Registration/Registration";
import SignUpSetEmail from "../SignUp/SetEmail/SetEmail";
import SuccessDialog from "../SignUp/SuccessDialog/SuccessDialog";
import SetPhoneNumber from "../SignUp/SetPhoneNumber/SetPhoneNumber";
import PasswordReset from "../PasswordReset/PasswordReset";
import PasswordResetCodeVerification from "../PasswordReset/CodeVerification/CodeVerification";
import PasswordResetEmail from "../PasswordResetEmail/PasswordResetEmail";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";
import Button from "../Button/Button";

// Icons
import CheckNiceIcon from "../../icons/CheckNiceIcon";
import BlockerIcon from "../../icons/BlockerIcon";

// Hooks
import useTheme from "../ThemeProvider/useTheme/useTheme";

// Utils
import {
  clientWidth,
  generatePassword,
  isValidFirstName,
  isValidLastName,
} from "../../utils";
import { modalStyles } from "../../constants";

const isLoginPath = (pathname) => {
  return pathname.includes("/login") || pathname.includes("/become-a-member");
};

export default function LoginPage() {
  const history = useHistory();
  const location = useLocation();

  const { theme } = useTheme();
  const { region } = useRegion();

  const {
    referralCode,
    migrationCode,
    registrationCode,
    applyReferral,
    setReferralCode,
    setMigrationCode,
    signUp,
    signUpWithApple,
    signUpWithGoogle,
    signUpWithFacebook,
    checkEmail,
    verifyPhoneNumber,
    verifyPhoneNumberOrEmailForSimpleLogin,
    sendEmailCode,
    setPromoCode,
  } = useAppState();

  const {
    authState,
    isAppleSignIn,
    identityToken,
    authorizationCode,
    accessToken, // For Facebook login
    authVendor,
    userPhoneCode: phoneCode,
    userPhoneNumber: phoneNumber,
    userPassword,
    userFirstName: firstName,
    userFamilyName: familyName,
    userEmail,
    setUserFirstName,
    setUserFamilyName,
    setUserEmail,
    setUserPhoneCode,
    setUserPhoneNumber,
    setAuthState,
    setAuthVendor,
    setAccessToken,
    setIdentityToken,
    setIsAppleSignIn,
    // setMigrationCode,
    setHasAuthError,
  } = useSignUpState();

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalMsg, setInfoModalMsg] = useState("");

  const infoModalResolveRef = useRef(null);

  let CurrentState,
    onNext = () => {};

  const onResetPasswordByEmailClick = useCallback(() => {
    setAuthState("passwordResetEmail");
  }, [userEmail]);

  const onCreateNewAccountClick = () => setAuthState("verifyPhoneNumber");

  const ctaText = "Continue";

  useEffect(() => {
    // From Coach Profile page
    const query = new URLSearchParams(window.location.search);

    const authState = query.get("authState");

    if (authState) {
      setAuthState(authState);
    }

    const authVendor = query.get("authVendor");

    if (authVendor) {
      setAuthVendor(authVendor);
    } else {
      setAuthVendor(undefined);
    }

    const accessToken = query.get("accessToken");

    if (accessToken) {
      setAccessToken(accessToken);
    }

    const identityToken = query.get("identityToken");

    if (identityToken) {
      setIdentityToken(identityToken);
    }

    const email = query.get("email");

    if (email) {
      setUserEmail(email);
    }

    const firstName = query.get("firstName");

    if (firstName) {
      setUserFirstName(firstName);
    }

    const lastName = query.get("lastName");

    if (lastName) {
      setUserFamilyName(lastName);
    }

    const migrationCode = query.get("migrationCode");

    if (migrationCode) {
      setMigrationCode(migrationCode);
      setAuthState("passwordResetEmail");
    }

    setHasAuthError(false);
  }, [location]);

  const openInfoModal = useCallback((msg, title = "Sorry") => {
    return new Promise((resolve, reject) => {
      infoModalResolveRef.current = resolve;

      setIsInfoModalOpen(true);
      setInfoModalTitle(title);
      setInfoModalMsg(msg);
    });
  }, []);

  const closeInfoModal = useCallback(() => {
    infoModalResolveRef.current();
    infoModalResolveRef.current = null;

    setIsInfoModalOpen(false);
  }, []);

  const redirectToPreviousUrl = useCallback(
    (userInfo) => {
      const urlParams = new URLSearchParams(window.location.search);
      const returnTo = urlParams.get("return_to");

      if (returnTo) {
        urlParams.delete("return_to");
        urlParams.delete("authState");

        const returnUrl = new URL(
          decodeURIComponent(returnTo),
          window.location.origin
        );
        const pathname = returnUrl.pathname;
        const search = returnUrl.search;

        if (userInfo && !userInfo.questionnaireCompleted) {
          history.replace({
            pathname: "/questionnaire",
            state: location.state,
            search: search
              ? `${search}${
                  urlParams.size > 0 ? `&${urlParams.toString()}` : ""
                }`
              : urlParams.size > 0
              ? `?${urlParams.toString()}`
              : "",
          });
        } else {
          history.replace({
            pathname,
            state: location.state,
            search: search
              ? `${search}${
                  urlParams.size > 0 ? `&${urlParams.toString()}` : ""
                }`
              : urlParams.size > 0
              ? `?${urlParams.toString()}`
              : "",
          });
        }
      } else {
        // Apple Login??
        const stateFrom = location && location.state && location.state.from;

        let returnToUrl =
          typeof stateFrom === "object" ? stateFrom.pathname : stateFrom;

        if (returnToUrl && returnToUrl.includes("/login")) {
          returnToUrl = "";
        }

        if (userInfo && !userInfo.questionnaireCompleted) {
          if (returnToUrl) {
            history.replace({
              pathname: "/questionnaire",
              search: `?return_to=${returnToUrl}`,
            });
          } else {
            history.replace("/questionnaire");
          }
        } else {
          history.replace(
            returnToUrl || {
              pathname: "/partners",
              //search: window.location.search,
            }
          );
        }
      }
    },
    [history, location]
  );

  const onSignUp = (userInfo) => {
    if (!userInfo) {
      return;
    }

    if (referralCode && false) {
      applyReferral(userInfo.id, referralCode)
        .then((res) => {
          // Referral code is valid

          return openInfoModal(
            `You received ${res.data.symbol}${res.data.receiverReward} referral credits.`,
            "Referral Bonus Applied"
          );
        })
        .catch((res) => {
          return openInfoModal(res.message, "Link Error");
        })
        .then(() => {
          redirectToPreviousUrl(userInfo);
        })
        .finally(() => {
          // Regardless the referral code is valid or not we don't need it anymore
          // (so it won't be applied on re-login for example)
          setReferralCode("");

          Cookies.remove("referral");
        });
    } else {
      redirectToPreviousUrl(userInfo);
    }
  };

  const onSignIn = (userInfo) => {
    if (!userInfo) {
      return;
    }

    redirectToPreviousUrl(userInfo);
  };

  const handleSignIn = (userInfo, returnTo, applelogin) => {
    let returnToUrl;
    let stateFrom;

    if (applelogin) {
      returnToUrl = window.localStorage.getItem("apple-redirect-return-to");
      stateFrom = window.localStorage.getItem("apple-redirect-state-from");

      window.localStorage.removeItem("apple-redirect-return-to");
      window.localStorage.removeItem("apple-redirect-state-from");
    }

    returnToUrl = returnToUrl || returnTo;

    if (typeof returnToUrl !== "string") {
      returnToUrl = returnToUrl && returnToUrl.pathname;
    }

    // FIXME quick fix to prevent to return to the login page again
    if (returnToUrl && !isLoginPath(returnToUrl)) {
      const query = new URLSearchParams(returnToUrl.split("?")[1]);

      // if (isLoginPath(returnToUrl)) {
      //   returnToUrl = "/partners";
      // }

      if (returnToUrl.includes("/partners")) {
        query.append("apple_login", "yes");
      }

      if (
        userInfo &&
        userInfo.customer &&
        !userInfo.customer.questionnaireCompleted
      ) {
        history.push("/questionnaire");
      } else {
        history.replace({
          pathname: returnToUrl.split("?")[0],
          state: { from: stateFrom },
          search: query.toString(),
        });
      }
      //history.replace(returnToUrl);
    } else {
      if (!applelogin) {
        stateFrom = location && location.state && location.state.from;
        // } else if (stateFrom && stateFrom === "/lifestyle") {
        // setPromoCode("TBHOL30");
      }

      // FIXME make more robust, quick fix for now
      if (stateFrom && isLoginPath(stateFrom)) {
        stateFrom = undefined;
      }

      // On login `state.from` is populated, so we preserve search params that way.
      if (
        userInfo &&
        userInfo.customer &&
        !userInfo.customer.questionnaireCompleted
      ) {
        history.push("/questionnaire");
      } else {
        history.replace(
          stateFrom || {
            pathname: "/partners",
            // search: window.location.search,
            search: applelogin ? "?apple_login=yes" : "",
          }
        );
      }
    }
    // window.location.href = ;
  };

  const handleSignUp = useCallback(
    ({ email, password, code, onNext }) => {
      //email = email || userEmail;
      password = password || userPassword;
      code = code || registrationCode;

      if (
        email &&
        (!code ||
          authState ===
            "loginWithEmail") /* || authState === "codeVerificationEmailSignUp"*/
      ) {
        checkEmail(email)
          .then((res) => {
            if (res.status === "MIGRATION_CUSTOMER" && res.migrationData) {
              const { phoneCode, phoneNumber } = res.migrationData;

              setUserPhoneCode(phoneCode);
              setUserPhoneNumber(phoneNumber);

              setAuthState("codeVerificationEmailSignUp");
              setAuthVendor("");

              return sendEmailCode(email);
              // It's email check anyway
              // return verifyPhoneNumber({
              //   phoneCode,
              //   phoneNumber,
              //   authVendor: "",
              // });
            } else if (res.status === "EXISTING_CUSTOMER") {
              // Just regular login

              // Redirect logic is in LoginPage `onSignIn` - no
              //return verifyEmail(email);
              return verifyPhoneNumberOrEmailForSimpleLogin({ email }).then(
                (res) => {
                  setAuthState("loginWithEmail" /*"codeVerificationEmail"*/);

                  return res;
                }
              );
            } else if (res.status === "NEW_CUSTOMER") {
              setAuthState("setPhoneNumber");
              setAuthVendor("");
            }

            return res;
          })
          .then((res) => {
            if (res.status || res.verified) {
              onNext && onNext(res);
            }

            // if (res.verified) {
            //   setAuthState("passwordSet");
            // }
          })
          .catch(() => {
            setHasAuthError(true);
          });
      } // {
      else if (identityToken && authorizationCode) {
        signUpWithApple(
          firstName,
          familyName,
          password,
          phoneCode,
          phoneNumber,
          code,
          email || userEmail,
          identityToken,
          authorizationCode,
          region
        )
          .then((res) => {
            // onNext && onNext(res);
            onSignUp(res);
          })
          .catch(() => {
            setHasAuthError(true);
          });
      } else if (identityToken && authVendor === "google") {
        signUpWithGoogle(
          firstName,
          familyName,
          phoneCode,
          phoneNumber,
          code,
          email || userEmail,
          identityToken,
          region
        )
          .then((res) => {
            // onNext && onNext(res);
            onSignUp(res);
          })
          .catch(() => {
            setHasAuthError(true);
          });
      } else if (accessToken && authVendor === "facebook") {
        signUpWithFacebook(
          firstName,
          familyName,
          phoneCode,
          phoneNumber,
          code,
          email || userEmail,
          accessToken,
          region
        )
          .then((res) => {
            // onNext && onNext(res);
            onSignUp(res);
          })
          .catch(() => {
            setHasAuthError(true);
          });
      } else {
        signUp(
          firstName,
          familyName,
          /*password: */ generatePassword(12),
          phoneCode,
          phoneNumber,
          code,
          email || userEmail,
          region
        )
          .then((res) => {
            // onNext && onNext(res);
            onSignUp(res);
          })
          .catch(() => {
            setHasAuthError(true);
          });
      }
    },
    [
      authVendor,
      authState,
      registrationCode,
      migrationCode,
      identityToken,
      authorizationCode,
      phoneCode,
      phoneNumber,
      userPassword,
      firstName,
      familyName,
      userEmail,
      region,
    ]
  );

  const handleNext = useCallback(
    (_, res) => {
      if (res && res.customer) {
        onSignIn(res.customer);

        return;
      }

      // Migrated user verified phone number
      if (res.registrationCode && (res.migrationCode || migrationCode)) {
        handleSignUp({ code: res.registrationCode });

        return;
      }

      if (
        res.registrationCode ||
        (isAppleSignIn && authVendor !== "" && authVendor === undefined) ||
        authVendor === "google" ||
        authVendor === "facebook"
      ) {
        //? // ? userFirstName && userFamilyName ?  :
        setAuthState("setFullName");
      } else if (res.migrationCode) {
        // Migrated user verified email
        setAuthState("setPhoneNumber" /*"setPhoneNumberMigration"*/);

        // return;
        // } else {
        //   setAuthState("passwordSet");
      }
    },
    [isAppleSignIn, authVendor, migrationCode, handleSignUp]
  );

  switch (authState) {
    case "login":
      // case "loginWithEmail":
      CurrentState = Login;
      onNext = onSignIn;

      break;
    case "verifyPhoneNumber":
      CurrentState = SignUpForm;
      onNext = () => setAuthState("codeVerification");

      break;
    case "codeVerification":
    case "codeVerificationLogin":
    case "codeVerificationEmail":
    case "codeVerificationEmailSignUp":
    case "loginWithEmail":
      CurrentState = SignUpCodeVerification;
      onNext = handleNext;

      break;
    // case "codeVerificationEmail":
    //   CurrentState = SignUpCodeVerification;
    //   onNext = () => setAuthState("passwordSet");
    //
    //   break;
    case "passwordSet":
      CurrentState = PasswordConfirmForm;
      onNext = () => setAuthState("setFullName"); // authVendor === "email" ?

      break;
    case "setFullName":
      CurrentState = Registration;
      onNext = () => setAuthState("setEmail");

      break;
    case "setEmail":
      CurrentState = SignUpSetEmail;
      onNext = handleSignUp; //onSignIn;

      break;
    case "registrationSuccess":
      CurrentState = SuccessDialog;

      break;
    case "passwordResetCodeVerification":
      CurrentState = PasswordResetCodeVerification;

      break;
    case "passwordReset":
      CurrentState = PasswordReset;

      break;
    case "passwordResetEmail":
      CurrentState = PasswordResetEmail;

      break;
    case "setPhoneNumber":
      CurrentState = SetPhoneNumber;
      setIsAppleSignIn(!migrationCode);
      // After services we got here
      onNext = () => setAuthState("codeVerification");

      break;
    default:
      CurrentState = SignUpForm;
  }

  return (
    <>
      <ScrollToTopOnMount />
      {/*<Title></Title>*/}
      <Header showLinks />

      <div className="tbk-flex tbk-min-h-screen tbk-flex-col tbk-items-center tbk-pt-5 tbk-pb-8 md:tbk-justify-center md:tbk-pt-6">
        <div className={"tbk-relative tbk-w-full tbk-p-3 sm:tbk-w-120"}>
          {authState === "" || authState === "signUp" ? (
            <SignUpForm
              title={"Sign Up or Login"}
              ctaText={ctaText}
              dividerText={"Or sign-up with:"}
              returnTo={location.state && location.state.from}
              onNext={(authVendor, params, isLogin, isEmailLogin) =>
                authVendor === "apple" ||
                authVendor === "google" ||
                authVendor === "facebook"
                  ? setAuthState("setPhoneNumber")
                  : authVendor === "email"
                  ? setAuthState("setEmail")
                  : setAuthState(
                      isLogin
                        ? "codeVerificationLogin"
                        : isEmailLogin
                        ? "codeVerificationEmail"
                        : "codeVerification"
                    )
              }
              onSignIn={handleSignIn}
            />
          ) : (
            React.createElement(CurrentState, {
              isLogin:
                authState === "loginWithEmail" ||
                authState === "codeVerificationLogin" ||
                authState === "codeVerificationEmail", // ???
              isSignUp: authState === "codeVerificationEmailSignUp", // is it enough??
              withEmail:
                authState === "codeVerificationEmail" ||
                authState === "loginWithEmail",
              ctaText,
              onNext,
              onResetPasswordClick: onResetPasswordByEmailClick,
              onCreateNewAccountClick,
            })
          )}
        </div>
      </div>

      <Modal
        appElement={/*__CLIENT__ && */ document.getElementById("#app")}
        isOpen={isInfoModalOpen}
        // onAfterOpen={afterOpenInfoModal}
        onRequestClose={closeInfoModal}
        style={
          clientWidth() < theme.responsive.breakpoints.sm
            ? modalStyles.infoMobile
            : modalStyles.info
        }
        contentLabel="Info Modal"
      >
        <div className="modal-info tbk-flex tbk-flex-col tbk-items-center">
          <h3 className="tbk-text-title-bold tbk-mt-0 tbk-mb-1 tbk-text-center tbk-text-grey-main">
            {infoModalTitle}
          </h3>
          <p className="tbk-text-main tbk-mb-4 tbk-max-w-full tbk-text-center tbk-text-grey-main">
            {infoModalMsg}
          </p>
          {infoModalTitle === "Referral Bonus Applied" ? (
            <CheckNiceIcon size={40} className="tbk-mb-4 tbk-text-green" />
          ) : (
            <BlockerIcon size={40} className="tbk-mb-4 tbk-text-green" />
          )}
          <Button className="tbk-w-full" onClick={closeInfoModal}>
            Ok
          </Button>
        </div>
      </Modal>
    </>
  );
}
