/**
 * Preferred workout picker for questionnaire
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/11/26
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useEffect, useState, useCallback } from "react";
import { useAppState } from "../../../state";

// Components
import Button from "../../Button/Button";

// Icons
import ArrowRight from "../../../icons/ArrowRightIcon";
import Calendar from "../../../icons/Calendar24Icon";
import CaretDownIcon from "../../../icons/CaretDownIcon";

const Checkbox = ({ children, className, id, name, value, onChange }) => {
  const handleChange = React.useCallback(
    (e) => {
      onChange && onChange(!value);
    },
    [value]
  );

  return (
    <div className={className}>
      <input
        id={id}
        type="checkbox"
        name={name}
        disabled={false}
        className="tbk-checkbox-big"
        checked={value}
        onChange={handleChange}
      />
      <label
        htmlFor={id}
        tabIndex="0"
        className="tbk-text-small tbk-mb-0 tbk-flex tbk-h-4 tbk-items-center tbk-pl-6 tbk-pt-1 tbk-pb-1 tbk-text-primary"
      >
        {children}
      </label>
    </div>
  );
};

const PreferredWorkoutPicker = ({ questions, onNext, onSkip }) => {
  const { isFetching } = useAppState();

  // const [questions, setQuestions] = useState([]);
  const [preferredWorkouts, setPreferredWorkouts] = useState(new Set());
  const [canSend, setCanSend] = useState(false);

  const handleCheckboxChange = (workout) => {
    setPreferredWorkouts((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(workout)) {
        newSet.delete(workout);
      } else {
        newSet.add(workout);
      }
      if (newSet.size) {
        setCanSend(true);
      } else {
        setCanSend(false);
      }
      return newSet;
    });
  };

  const handleSubmit = useCallback(
    (evt) => {
      evt && evt.preventDefault();
      onNext({ preferredWorkouts: Array.from(preferredWorkouts) });
    },
    [preferredWorkouts]
  );

  const handleSkip = useCallback(
    (evt) => {
      onSkip && onSkip({ preferredWorkouts: Array.from(preferredWorkouts) });
    },
    [onSkip, preferredWorkouts]
  );

  return (
    <>
      <form className="tbk-mb-4" onSubmit={handleSubmit}>
        <div className="tbk-text-title-bold tbk-mb-1">
          Preferred Workout Styles
        </div>
        <div className="tbk-text-main tbk-mb-4 tbk-text-blue-grey">
          Which workout style do you prefer?
        </div>

        <div className="tbk-mb-7">
          {questions && questions.id
            ? questions.answers.map((val) => (
                <Checkbox
                  key={val.id}
                  id={val.id}
                  className="tbk-mb-1 tbk-p-1"
                  value={preferredWorkouts.has(val.id)}
                  onChange={() => handleCheckboxChange(val.id)}
                >
                  <div className="tbk-text-h3-subtitle tbk-mb-0 tbk-text-primary">
                    {val.answer}
                  </div>
                </Checkbox>
              ))
            : null}
        </div>

        <Button
          type="submit"
          className="tbk-mb-3 tbk-w-full"
          disabled={!canSend || isFetching}
          endIcon={<ArrowRight />}
          showLoader={isFetching}
        >
          Next
        </Button>
      </form>
      <div className="tbk-text-center">
        <button
          className="tbk-text-main tbk-inline-block tbk-text-blue-grey tbk-underline hover:tbk-text-blue-grey-light"
          disabled={isFetching}
          onClick={handleSkip}
        >
          Skip Questionnaire
        </button>
      </div>
    </>
  );
};

export default PreferredWorkoutPicker;
