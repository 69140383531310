import React from "react";

export default function CalendarIcon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-shrink-0`}
    >
      <path
        d="M17.5987 5.51323H15.8635V4.80706C15.8635 4.36318 15.5004 4 15.0565 4C14.6126 4 14.2494 4.36318 14.2494 4.80706V5.51323H9.77024V4.80706C9.77024 4.36318 9.40706 4 8.96318 4C8.51929 4 8.15612 4.36318 8.15612 4.80706V5.51323H6.42094C5.53318 5.51323 4.80682 6.23959 4.80682 7.12735V18.3858C4.80682 19.2736 5.53318 19.9999 6.42094 19.9999H17.5785C18.4663 19.9999 19.1926 19.2736 19.1926 18.3858V7.12735C19.2128 6.23959 18.4865 5.51323 17.5987 5.51323ZM17.5785 18.3656H6.42094V11.5864H17.5785V18.3656Z"
        fill="currentColor"
      />
    </svg>
  );
}
