/**
 * Verification step for Membership cancellation
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/12/12
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useEffect, useState, useCallback } from "react";
import { useAppState } from "../../../state";

// Components
import Button from "../../Button/Button";

// Icons
import ArrowRight from "../../../icons/ArrowRightIcon";
import Calendar from "../../../icons/Calendar24Icon";
import CaretDownIcon from "../../../icons/CaretDownIcon";

const reasons = [
  {
    id: "reason0",
    label: "Not enough classes available",
  },
  {
    id: "reason1",
    label: "Classes are not in convenient locations",
  },
  {
    id: "reason2",
    label: "Workouts offered don’t match my preferences",
  },
  {
    id: "reason3",
    label: "Membership price is too high",
  },
  {
    id: "reason4",
    label: "I found an alternative fitness option",
  },
  {
    id: "reason5",
    label: "Service Quality Issue",
  },
];

const Checkbox = ({ children, className, id, name, value, onChange }) => {
  const handleChange = React.useCallback(
    (e) => {
      onChange && onChange(!value);
    },
    [value]
  );

  return (
    <div className={className}>
      <input
        id={id}
        type="checkbox"
        name={name}
        disabled={false}
        className="tbk-checkbox-big"
        checked={value}
        onChange={handleChange}
      />
      <label
        htmlFor={id}
        tabIndex="0"
        className="tbk-text-main tbk-pt-1_ tbk-pb-1_ tbk-mb-0 tbk-flex tbk-min-h-[32px] tbk-items-center tbk-pl-6 !tbk-leading-normal tbk-text-primary"
      >
        {children}
      </label>
    </div>
  );
};

const Verification = ({ onSend }) => {
  const { isFetching } = useAppState();

  // const [questions, setQuestions] = useState([]);
  const [cancellationReasons, setCancellationReasons] = useState(new Set());
  const [canSend, setCanSend] = useState(false);

  const handleCheckboxChange = (reason) => {
    setCancellationReasons((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(reason)) {
        newSet.delete(reason);
      } else {
        newSet.add(reason);
      }
      if (newSet.size) {
        setCanSend(true);
      } else {
        setCanSend(false);
      }
      return newSet;
    });
  };

  const handleSubmit = useCallback(
    (evt) => {
      evt && evt.preventDefault();
      onSend(Array.from(cancellationReasons));
    },
    [cancellationReasons]
  );

  return (
    <>
      <form className="" onSubmit={handleSubmit}>
        <div className="tbk-text-h3-subtitle tbk-mb-2 tbk-text-primary">
          Why are you cancelling your TruBe membership?
        </div>

        <div className="tbk-mb-4">
          {reasons.map((val) => (
            <Checkbox
              key={val.id}
              id={val.id}
              className="tbk-mb-1 tbk-py-1"
              value={cancellationReasons.has(val.label)}
              onChange={() => handleCheckboxChange(val.label)}
            >
              {val.label}
            </Checkbox>
          ))}
        </div>

        <Button
          type="submit"
          className="tbk-w-full"
          disabled={!canSend || isFetching}
          // endIcon={<ArrowRight />}
          showLoader={isFetching}
        >
          Send
        </Button>
      </form>
    </>
  );
};

export default Verification;
