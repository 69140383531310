// Keep in mind that these are the styles from flatpickr package
// See troubleshooting section in case you have problems importing the styles
/**
 * Date of Birth picker for questionnaire
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/11/25
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

//import "flatpickr/dist/themes/light.css";

import React, { useState, useCallback } from "react";
import Flatpickr from "react-flatpickr";
// import { Component } from "react";
import { useAppState } from "../../../state";
import { useRegion } from "../../../context/RegionProvider";

// Components
import Button from "../../Button/Button";

// Icons
import ArrowRight from "../../../icons/ArrowRightIcon";
import Calendar from "../../../icons/Calendar24Icon";
import CaretDownIcon from "../../../icons/CaretDownIcon";

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
  return (
    <div
      className="tbk-relative tbk-mb-4 lg:tbk-mb-7"
      // className="tbk-flex tbk-justify-between_ tbk-pt-1.5 tbk-pb-1.5 tbk-pl-2 tbk-pr-1 tbk-border tbk-border-blue-grey-light tbk-rounded-lg"
    >
      <Calendar className="tbk-pointer-events-none tbk-absolute tbk-left-2 tbk-top-1.5 tbk-mr-1 tbk-grow-0 tbk-text-green" />
      <div className="tbk-grow">
        <input {...props} defaultValue={defaultValue} ref={inputRef} />
      </div>
      <CaretDownIcon className="tbk-pointer-events-none tbk-absolute tbk-top-1.5 tbk-right-2 tbk-grow-0" />
    </div>
  );
};

const prevDay = new Date(); // Create a copy of maxDate
prevDay.setDate(prevDay.getDate() - 1); // Subtract one day

const DobPicker = ({ onNext, onSkip }) => {
  const { region } = useRegion();

  const {
    // user,
    // hasTriedToAutoLogin,
    // isLoggedIn,
    userDoB,
    setUserDoB,
    isFetching,
  } = useAppState();

  const [date, setDate] = useState(userDoB);
  const [maxDate, setMaxDate] = useState(prevDay);
  const [canSend, setCanSend] = useState(false);

  const handleSubmit = useCallback(
    (evt) => {
      evt && evt.preventDefault();
      if (date) {
        // Get the UTC date and time
        var nowUTC = Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        );
        onNext({ date: new Date(nowUTC) });
      }
    },
    [date]
  );

  const handleSkip = useCallback(
    (evt) => {
      onSkip && onSkip({ date });
    },
    [onSkip, date]
  );

  return (
    <>
      <form className="tbk-mb-4" onSubmit={handleSubmit}>
        <div className="tbk-text-title-bold tbk-mb-1">Date of Birth</div>
        <div className="tbk-text-main tbk-mb-4 tbk-text-blue-grey">
          Please select your birthday:
        </div>

        <Flatpickr
          data-enable-time
          value={date}
          onChange={([date]) => {
            setCanSend(true);
            setUserDoB(date.getTime());
            setDate(date);
          }}
          options={{
            maxDate,
            disableMobile: true,
            prevArrow: `<span className="flatpickr-prev-month"><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z" fill="#6B7178"/></svg></span>`,
            nextArrow: `<span className="flatpickr-next-month"><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.6 6L0 1.4L1.4 0L7.4 6L1.4 12L0 10.6L4.6 6Z" fill="#6B7178"/></svg></span>`,
            dateFormat: region === "US" ? "F/d/Y" : "d/M/Y",
          }}
          className="tbk-mb-2"
          render={({ defaultValue, value, ...props }, ref) => {
            return (
              <CustomInput
                defaultValue={defaultValue}
                inputRef={ref}
                placeholder={
                  region === "US" ? "Month/day/year" : "day/month/year"
                }
              />
            );
          }}
        />
        <Button
          type="submit"
          className="tbk-mb-3 tbk-w-full"
          //variant="secondary"
          disabled={!canSend || isFetching}
          endIcon={<ArrowRight />}
          showLoader={isFetching}
          // onClick={}
        >
          Next
        </Button>
      </form>
      <div
        className="tbk-text-main tbk-cursor-pointer tbk-text-center tbk-text-blue-grey tbk-underline hover:tbk-text-blue-grey-light"
        onClick={handleSkip}
      >
        Skip Questionnaire
      </div>
    </>
  );
};

export default DobPicker;
