import React, { useCallback, useEffect, useRef, useState } from "react";
import Transition, {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
} from "react-transition-group/Transition";
import Lottie from "react-lottie";

// Hooks
import useTheme from "../../ThemeProvider/useTheme/useTheme";

// Utils
import { clientWidth } from "../../../utils";

import animationDataMobile from "../../../lotties/hand-scroll";
import animationData from "../../../lotties/scroll-down";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback/useDebouncedCallback";

const SCROLL_DOWN_TRESHOLD = 150;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ScrollingIndicator = ({ className, onClick }) => {
  const { theme } = useTheme();

  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const [storedPageYOffset, setStoredPageYOffset] = useState();
  const [_lastKnownPageYOffset, setLastKnownPageYOffset] = useState();

  const lastKnownPageYOffsetRef = useRef();
  const showScrollIndicatorRef = useRef();
  const showScrollIndicatorTimeoutRef = useRef();

  useEffect(() => {
    showScrollIndicatorTimeoutRef.current = setTimeout(() => {
      if (showScrollIndicatorTimeoutRef.current) {
        showScrollIndicatorTimeoutRef.current = null;
        showScrollIndicatorRef.current = true;
        setShowScrollIndicator(true);
      }
    }, 3000);

    return () => {
      if (showScrollIndicatorTimeoutRef.current) {
        clearTimeout(showScrollIndicatorTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showScrollIndicatorRef.current === false) {
      window.removeEventListener("scroll", handleScrollDebounced);
    }
  }, [showScrollIndicator]);

  useEffect(
    () => {
      window.addEventListener("scroll", handleScrollDebounced);

      return () => {
        window.removeEventListener("scroll", handleScrollDebounced);
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleScrollUp = useCallback(() => {}, []);

  const handleScrollDown = useCallback(() => {
    const windowPageYOffset = window.pageYOffset;

    if (
      windowPageYOffset > SCROLL_DOWN_TRESHOLD &&
      showScrollIndicatorRef.current === true
    ) {
      showScrollIndicatorRef.current = false;
      setShowScrollIndicator(false);
    }

    setStoredPageYOffset(windowPageYOffset);
  }, []);

  const handleScroll = useCallback(
    () => {
      const lastKnownPageYOffset = lastKnownPageYOffsetRef.current;
      const windowPageYOffset = window.pageYOffset;

      // Fixes iOS bug when Header cannot "dock"
      if (lastKnownPageYOffset === windowPageYOffset) {
        return;
      }

      if (lastKnownPageYOffset > windowPageYOffset) {
        handleScrollUp();
      } else {
        handleScrollDown();
      }

      setLastKnownPageYOffset(windowPageYOffset);
      lastKnownPageYOffsetRef.current = windowPageYOffset;
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleScrollDebounced = useDebouncedCallback(handleScroll, 300);

  const handleClick = useCallback(() => {
    if (showScrollIndicatorRef.current) {
      onClick && onClick();
    }
  }, [onClick]);

  return (
    <div className={className} onClick={handleClick}>
      <Transition
        in={showScrollIndicator}
        timeout={500}
        //addEndListener={handleTransitionEnd}
      >
        {(status, innerProps) => (
          <div
            {...innerProps}
            className={`tbk-transition-all tbk-duration-500 ${
              status === ENTERING || status === ENTERED
                ? "tbk-opacity-100"
                : status === EXITING || status === EXITED
                ? "tbk-pointer-events-none tbk-opacity-0"
                : ""
            }`}
          >
            <Lottie
              options={{
                ...defaultOptions,
                animationData:
                  clientWidth() < theme.responsive.breakpoints.sm
                    ? animationDataMobile
                    : animationData,
              }}
              height={clientWidth() < theme.responsive.breakpoints.sm ? 84 : 48}
              width={clientWidth() < theme.responsive.breakpoints.sm ? 105 : 48}
            />
          </div>
        )}
      </Transition>
    </div>
  );
};

export default ScrollingIndicator;
