import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

// Utils
import { getTz } from "../../../utils/date";

// Icons
import ArrowRightIcon from "../../../icons/ArrowRightIcon";
// import CheckNiceIcon from "../../../icons/CheckNiceIcon";
// import CheckNiceIcon from "../../../icons/CheckNiceOIcon";
import CheckNiceIcon from "../../../icons/CheckNiceIcon";

const EventCard = ({
  imgUrl,
  title,
  startDate,
  endDate,
  place,
  joined,
  soldOut,
  status,
  href,
  region,
  onClick,
}) => {
  const formattedDate = moment.utc(startDate).tz(getTz(region)).format("MMM D");
  let formattedStartTime = moment
    .utc(startDate)
    .tz(getTz(region))
    .format("h:mm");
  let formattedEndTime = moment.utc(endDate).tz(getTz(region)).format("h:mm A");

  formattedStartTime = formattedStartTime.replace(":00", "");
  formattedEndTime = formattedEndTime.replace(":00", "");

  return (
    <Link
      to={href}
      className="tbk-flex tbk-max-w-full tbk-flex-col tbk-overflow-hidden tbk-rounded-lg tbk-bg-basic-white tbk-shadow-trube"
      onClick={onClick}
    >
      <div className="tbk-relative tbk-pt-[59.2057761732852%]">
        <img
          src={imgUrl}
          alt={`${title} Class`}
          className="tbk-absolute tbk-top-0 tbk-w-full tbk-grow tbk-rounded-t-lg tbk-object-cover tbk-object-left-top"
        />
      </div>
      <div className="tbk-mt-4_ tbk-relative tbk-flex tbk-items-center tbk-justify-between tbk-p-2">
        {soldOut ? (
          <div className="tbk-text-small tbk-absolute tbk-right-1 tbk-top-0 tbk-rounded-b-lg tbk-bg-primary tbk-px-1 tbk-text-basic-white">
            Sold Out
          </div>
        ) : null}
        <div className="tbk-min-w-0">
          <h2
            className={`tbk-text-h3-subtitle tbk-mb-0.5 ${
              status === "SOLD_OUT" ? "tbk-text-blue-grey" : "tbk-text-primary"
            } tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap`}
          >
            {title}
          </h2>
          <div className="tbk-flex tbk-items-center">
            <div className="tbk-text-small tbk-mr-0.5 tbk-shrink-0 tbk-whitespace-nowrap tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-pt-0.5 tbk-pl-1 tbk-pr-1 tbk-pb-0.5 tbk-text-primary">
              {formattedDate}
            </div>
            <div className="tbk-text-small tbk-mr-0.5 tbk-shrink-0 tbk-whitespace-nowrap tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-pt-0.5 tbk-pl-1 tbk-pr-1 tbk-pb-0.5 tbk-text-primary">
              {formattedStartTime}-{formattedEndTime}
            </div>
            <div className=" tbk-text-small tbk-mr-0.5 tbk-min-w-0 tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-pt-0.5 tbk-pl-1 tbk-pr-1 tbk-pb-0.5 tbk-text-primary">
              {place}
            </div>
          </div>
        </div>
        <div className="tbk-ml-2">
          {joined ? (
            <CheckNiceIcon
              width={24}
              height={24}
              className="tbk-rounded-full tbk-border tbk-border-basic-white tbk-bg-green tbk-text-basic-white"
            />
          ) : (
            <ArrowRightIcon className="tbk-text-coral" />
          )}
        </div>
      </div>
    </Link>
  );
};

export default EventCard;
